// eslint-disable-next-line
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Image,
  Upload,
  Radio,
  Space,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [adminuser, setCurrentAdminUser] = useState("");

  const adminuserId = props.match.params.adminuser_id;

  useEffect(() => {
    props
      .getAdminUser(adminuserId)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          setCurrentAdminUser(resp.data.data);
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  }, [adminuserId, setCurrentAdminUser]);

  useEffect(() => {
    setIsBtnActive(true);
  }, [adminuser]);

  const defaultValues = {
    name: adminuser?.name || "",
    email: adminuser?.email || "",
    role: adminuser?.role || "admin",
    image: adminuser?.image || "default_avtar.png",
    button_text: adminuser?.button_text || "",
    button_link: adminuser?.button_link || "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    if (!isBtnActive) {
      values.button_text = "";
      values.button_link = "";
    }

    var formdata = new FormData();

    formdata.append("name", values.name);
    formdata.append("role", values.role);
    formdata.append("email", values.email);
    formdata.append(
      "image",
      values.media_file_name && values.media_file_name.length > 0
        ? values.media_file_name[0]["originFileObj"]
        : adminuser.image
    );

    props
      .updateAdminUser(adminuserId, formdata)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/adminusers");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  const updateForm = (e) => {
    const { value, id } = e.target;
    adminuser[id] = value;
    setCurrentAdminUser(adminuser);
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item
                name="name"
                label="Name"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Admin or Staff Name"
                  onChange={updateForm}
                />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Email"
                  onChange={updateForm}
                />
              </Form.Item>
              <Form.Item
                name="role"
                label="Role"
                className="teacher-schedule-edit-label"
              >
                <Radio.Group onChange={updateForm} value={adminuser.role}>
                  <Space direction="vertical">
                    <Radio value="admin">Admin</Radio>
                    <Radio value="staff">Staff</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="media_file_name"
                label="Photo"
                valuePropName="image"
                getValueFromEvent={normFile}
              >
                <Upload name="file">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Update
              </Button>
            </Form>
          </Card>
        </Col>
        <Col
          className="teacher-schedule-edit-container"
          span={4}
          style={{ marginLeft: 10 }}
        >
          <Card className="teacher-schedule-edit-card">
            <Image
              preview={false}
              src={adminuser.image}
              width={"100%"}
              className="switch"
            />
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    adminuser: state.adminuser,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
