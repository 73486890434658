import React, { Component, useState } from "react";
import { Table, Space, Col, Row } from "antd";
import { Button } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { DatePicker } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Download = ({ items, searchMonth }) => {
  const [loading, setLoading] = useState(false);
  var zip = new JSZip();
  var zipFilename =
    "receipts-" +
    searchMonth +
    "-" +
    moment().format("DD_MM_YYYY-HH:mm:ss") +
    ".zip";

  // add the links to your media/text/csv/other files

  var urls = [];

  if (items && items.length > 0) {
    items.forEach(function (item, index) {
      urls.push(item.invoice_url);
    });
  }

  const getFileContent = (url) => {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  };

  var downloadAll = async () => {
    try {
      setLoading(true);
      for (var i = 0; i < urls.length; i++) {
        var url = urls[i];
        var filename = "file-" + i + ".pdf";
        // console.log("filename>>", filename);
        // loading a file and add it in a zip file
        var data = await getFileContent(url);
        zip.file(filename, data, { binary: true });
      }
      var zipFile = await zip.generateAsync({ type: "blob" });
      saveAs(zipFile, zipFilename);
      setLoading(false);
    } catch (e) {
      console.log("File Error>", e?.message || "None");
      setLoading(false);
    }
  };

  return (
    <Button
      onClick={downloadAll}
      className="btn-download"
      disabled={loading ? true : false}
    >
      {loading ? <Spin indicator={antIcon} /> : " Download All"}
    </Button>
  );
};

class ReceiptList extends Component {
  state = {
    searchMonth: "",
  };

  onChange = (date, dateString) => {
    this.setState({ searchMonth: dateString });
    this.props.getReceipts(dateString);
  };

  componentDidMount() {
    this.props.getReceipts();
  }

  render() {
    const { searchMonth } = this.state;
    const { receipts } = this.props;

    const columns = [
      {
        title: "ID",
        dataIndex: "transaction_id",
        key: "transaction_id",
      },
      {
        title: "Month",
        dataIndex: "payment_month",
        key: "payment_month",
      },
      {
        title: "Action",
        key: "action",
        render: (_text, record) => (
          <Space size="middle">
            <a
              href={record.invoice_url}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="small">Download</Button>
            </a>
          </Space>
        ),
      },
    ];
    let results = [];
    if (receipts.length > 0) {
      results = receipts.map((row, index) => ({
        ...row,
        key: row.transaction_id,
      }));
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Receipts</h4>

            <DatePicker onChange={this.onChange} picker="month" />

            <Download items={results} searchMonth={searchMonth} />
          </Col>
        </Row>
        <Table columns={columns} dataSource={results} />
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    receipts: state.receipts,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(ReceiptList));
