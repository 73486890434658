import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Image,
  Popconfirm,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import config from "../../config";
const apiUrl = config.API_URL + "/api/image/";

const { TextArea } = Input;

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [, setIsBtnActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [notes, setNotes] = useState("");
  const [visibleA, setVisibleA] = React.useState(false);
  const [visibleR, setVisibleR] = React.useState(false);

  const { setCurrentFreeTrial, free_trial } = props;
  const free_trialId = props.match.params.free_trial_id;

  useEffect(() => {
    setCurrentFreeTrial(free_trialId);
  }, [free_trialId, setCurrentFreeTrial]);

  useEffect(() => {
    setIsBtnActive(true);
  }, [free_trial]);

  useEffect(() => {
    // form.setFieldsValue(defaultValues);
  }, [form]);

  const onApprove = (event) => {
    props
      .approveFreeTrial(free_trialId, notes)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/free-trials");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onReject = (event) => {
    if (notes === "") {
      setErrorMessage("Note is required!");
    }
    props
      .rejectFreeTrial(free_trialId)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/free-trials");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="free_trial-edit-container" span={12}>
          <Card className="free_trial-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Card
              className="free_trial-edit-card"
              style={{ whiteSpace: "pre-wrap" }}
            >
              <b>Student Name:</b>
              {` ${!!free_trial ? free_trial.student_name || "" : ""}\n`}
              <br />
              <b>Subject Names:</b>
              {` ${!!free_trial ? free_trial.subject_names || "" : ""}\n`}
              <br />
              <b>Payment Status:</b>
              {free_trial.payment_status === "pending" ? (
                <Tag color="blue">{` ${
                  !!free_trial ? free_trial.payment_status || "" : ""
                }\n`}</Tag>
              ) : free_trial.payment_status === "success" ? (
                <Tag color="green">{` ${
                  !!free_trial ? free_trial.payment_status || "" : ""
                }\n`}</Tag>
              ) : (
                <Tag color="red">{` ${
                  !!free_trial ? free_trial.payment_status || "" : ""
                }\n`}</Tag>
              )}
              <br />
            </Card>
          </Card>
        </Col>
        <Col className="free_trial-edit-container" span={12}>
          <Card className="free_trial-edit-card">
            <Image
              src={
                !!free_trial && !!free_trial.ic_card_image
                  ? apiUrl + free_trial.ic_card_image
                  : ""
              }
              preview={{
                src:
                  !!free_trial && !!free_trial.ic_card_image
                    ? apiUrl + free_trial.ic_card_image
                    : "",
              }}
              width={"100%"}
              className="switch"
            />

            {!!free_trial && free_trial.payment_status === "pending" && (
              <Row>
                <TextArea
                  rows={4}
                  value={notes}
                  onChange={(text) => {
                    setNotes(text.target.value);
                  }}
                  style={{ marginBottom: 10 }}
                />
                <Col className="free_trial-edit-container" span={11}>
                  <Popconfirm
                    title="Are you sure you want to approve this free_trial?"
                    visible={visibleA}
                    onConfirm={() => onApprove()}
                    onCancel={() => setVisibleA(false)}
                  >
                    <Button
                      style={{ backgroundColor: "#27AE60", padding: 10 }}
                      htmlType="submit"
                      type="primary"
                      onClick={() => {
                        setVisibleA(true);
                        setVisibleR(false);
                      }}
                      block
                    >
                      Approve
                    </Button>
                  </Popconfirm>
                </Col>
                <Col className="free_trial-edit-container" span={2}></Col>
                <Col className="free_trial-edit-container" span={11}>
                  <Popconfirm
                    title="Are you sure you want to reject this free_trial?"
                    visible={visibleR}
                    onConfirm={onReject}
                    onCancel={() => setVisibleR(false)}
                  >
                    <Button
                      style={{ backgroundColor: "#E74C3C", padding: 10 }}
                      htmlType="submit"
                      type="primary"
                      onClick={() => {
                        setVisibleR(true);
                        setVisibleA(false);
                      }}
                      block
                    >
                      Reject
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    free_trial: state.free_trial,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
