import config from "../config";
import history from "../common/history";

const onApiError = (e) => {
  throw e;
};

const onApiSuccess = (res) => res;

export const tokenStorage = (function () {
  const COOKIE_NAME = "startutor_admin_token";

  // Note: use local storage to store token whenever possible
  // if not found, downgrade to local variable, which will require users
  // to sign in after refreshing pages
  if (typeof localStorage !== "undefined") {
    return {
      get: () => {
        return localStorage.getItem(COOKIE_NAME);
      },
      set: (token) => {
        return localStorage.setItem(COOKIE_NAME, token);
      },
      remove: () => {
        return localStorage.removeItem(COOKIE_NAME);
      },
    };
  }

  let token = null;

  return {
    get: () => {
      return token;
    },
    set: (tk) => {
      token = tk;
    },
    remove: () => {
      token = null;
    },
  };
})();

const invokeApi = ({
  path,
  method = "GET",
  headers = {},
  authorization = false,
  isFile = false,
  body,
}) => {
  const apiUrl = config.API_URL + path;

  headers["x-api-key"] = config.API_KEY;

  if (authorization) {
    const token = tokenStorage.get();
    if (token && token.length && token !== "null") {
      headers.Authorization = `${token}`;
    }
  }
  if (!isFile && body) {
    body = body && JSON.stringify(body);
  }
  return fetch(apiUrl, {
    method,
    body,
    headers,
  }).then((results) => {
    if (results.status === 401 || results.status === 403) {
      logout();
      throw new Error("Unauthorized");
    }
    if (results.status !== 200) {
      return results.json().then((data) => {
        return Promise.reject(data);
      });
    }

    return results
      .json()
      .then((json) => {
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
  });
};

export const logout = () => {
  tokenStorage.remove();
  history.push("/login");
};

const processToken = (result) => {
  if (result.status && result.token) {
    tokenStorage.set(result.token);
  }
  return result;
};

export const login = (data) =>
  invokeApi({
    method: "POST",
    path: "/admin/login",
    headers: { "Content-Type": "application/json" },
    body: data,
  })
    .then((res) => {
      return processToken(res);
    })
    .then(onApiSuccess, onApiError);

export const updateTeacherSchedule = (schedule_id, data) =>
  invokeApi({
    method: "PUT",
    path: `/admin/teacher-schedules/${schedule_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const deletePayment = (id) =>
  invokeApi({
    method: "DELETE",
    path: `/admin/delete-payment/${id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const approvePayment = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/approve-manual-payment`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addAnnouncement = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/announcements`,
    authorization: true,
    body: data,
    isFile: true,
  }).then(onApiSuccess, onApiError);

export const rejectPayment = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/reject-manual-payment`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const failPayment = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/fail-manual-payment`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const approveFreeTrial = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/approve-free-trial`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const rejectFreeTrial = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/reject-free-trial`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addClassNote = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/add-class-note`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getUsers = () =>
  invokeApi({
    method: "GET",
    path: "/admin/users",
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getGrades = () =>
  invokeApi({
    method: "GET",
    path: "/admin/grades",
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getSubjects = (grade_id = undefined) =>
  invokeApi({
    method: "GET",
    path: grade_id ? `/admin/subjects?grade_id=${grade_id}` : `/admin/subjects`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getSchedules = (grade_id) =>
  invokeApi({
    method: "GET",
    path: `/admin/schedules?grade_id=${grade_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getTimeTable = (userId) =>
  invokeApi({
    method: "GET",
    path: `/admin/timetable/${userId}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const addFile = (file) =>
  invokeApi({
    method: "POST",
    path: `/admin/file`,
    authorization: true,
    body: file,
  }).then(onApiSuccess, onApiError);

export const getUser = (user_id) =>
  invokeApi({
    method: "GET",
    path: `/admin/users/${user_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getAdminProfile = () =>
  invokeApi({
    method: "GET",
    path: `/admin/check`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getTeacherSchedules = (
  schedule_id = null,
  data_index = null,
  search = null
) =>
  invokeApi({
    method: "GET",
    path: `/admin/teacher-schedules${!!schedule_id ? "/" + schedule_id : ""}${
      !!data_index && !!search
        ? "?data_index=" + data_index + "&query=" + search
        : ""
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const deactiveTeacherSchedule = (schedule_id) =>
  invokeApi({
    method: "PATCH",
    path: `/admin/teacher-schedules/${schedule_id}/deactive`,
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getClassNotes = (
  schedule_id,
  notes_id = null,
  data_index = null,
  search = null
) =>
  invokeApi({
    method: "GET",
    path: `/admin/class-notes${
      !!notes_id ? "/" + notes_id : ""
    }?schedule_id=${schedule_id}${
      !!data_index && !!search
        ? "&data_index=" + data_index + "&query=" + search
        : ""
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const deleteClassNote = (notes_id) =>
  invokeApi({
    method: "DELETE",
    path: `/admin/class-notes/${notes_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const deleteAnnouncement = (announcement_id) =>
  invokeApi({
    method: "DELETE",
    path: `/admin/announcements/${announcement_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const editClassNote = (notes_id, data) =>
  invokeApi({
    method: "PUT",
    path: `/admin/class-notes/${notes_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const updateAnnouncement = (announcement_id = null, data) =>
  invokeApi({
    method: "PUT",
    path: `/admin/announcements${
      !!announcement_id ? "/" + announcement_id : ""
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getDashboardStats = () =>
  invokeApi({
    method: "GET",
    path: `/admin/dashboard-stats`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getPayments = (
  payment_id = null,
  data_index = null,
  search = null
) =>
  invokeApi({
    method: "GET",
    path: `/admin/payments${!!payment_id ? "/" + payment_id : ""}${
      !!data_index && !!search
        ? "?data_index=" + data_index + "&query=" + search
        : ""
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getAnnouncements = (
  announcement_id = null,
  data_index = null,
  search = null
) =>
  invokeApi({
    method: "GET",
    path: `/admin/announcements${
      !!announcement_id ? "/" + announcement_id : ""
    }${
      !!data_index && !!search
        ? "?data_index=" + data_index + "&query=" + search
        : ""
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getFreeTrials = (
  free_trial_id = null,
  data_index = null,
  search = null
) =>
  invokeApi({
    method: "GET",
    path: `/admin/free-trials${!!free_trial_id ? "/" + free_trial_id : ""}${
      !!data_index && !!search
        ? "?data_index=" + data_index + "&query=" + search
        : ""
    }`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getTeachers = () =>
  invokeApi({
    method: "GET",
    path: "/admin/teachers",
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getTeacher = (teacher_id) =>
  invokeApi({
    method: "GET",
    path: `/admin/teachers/${teacher_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const updateTeacher = (teacher_id, data) =>
  invokeApi({
    method: "PUT",
    path: `/admin/teachers/${teacher_id}`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addTeacher = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/teachers`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getAdminUsers = () =>
  invokeApi({
    method: "GET",
    path: "/admin/adminusers",
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getAdminUser = (adminuser_id) =>
  invokeApi({
    method: "GET",
    path: `/admin/adminusers/${adminuser_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const updateAdminUser = (adminuser_id, data) =>
  invokeApi({
    method: "PUT",
    path: `/admin/adminusers/${adminuser_id}`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addAdminUser = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/adminusers`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const updateAdminUserToggleActive = (adminuser_id) =>
  invokeApi({
    method: "PUT",
    path: `/admin/adminusers/${adminuser_id}/toggleactive`,
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const addTeacherSchedule = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/teacher-schedules`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getReceipts = (month = null) =>
  invokeApi({
    method: "GET",
    path: `/admin/receipts${!!month ? "?month=" + month : ""}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const updateUser = (userId, data) =>
  invokeApi({
    method: "PUT",
    path: `/admin/user/${userId}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const deleteUserAccount = (userId, data) =>
  invokeApi({
    method: "DELETE",
    path: `/admin/user/${userId}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addSubject = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/subject`,
    authorization: true,
    isFile: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const deleteSubject = (id) =>
  invokeApi({
    method: "DELETE",
    path: `/admin/subject/${id}`,
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const getKidTimeTable = (id) =>
  invokeApi({
    method: "GET",
    path: `/admin/kid-timetable/${id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const updateUserSchedule = (userId, data) =>
  invokeApi({
    method: "POST",
    path: `/admin/kid-timetable/${userId}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const addClassBlockDate = (data) =>
  invokeApi({
    method: "POST",
    path: `/admin/add-block-date`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const getClassBlockDates = (schedule_id, schedule_canceled_id = null) =>
  invokeApi({
    method: "GET",
    path: `/admin/block-dates${
      !!schedule_canceled_id ? "/" + schedule_canceled_id : ""
    }?schedule_id=${schedule_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);

export const editClassBlockDate = (schedule_canceled_id, data) =>
  invokeApi({
    method: "PUT",
    path: `/admin/block-date/${schedule_canceled_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
    body: data,
  }).then(onApiSuccess, onApiError);

export const deleteClassBlockDate = (schedule_canceled_id) =>
  invokeApi({
    method: "DELETE",
    path: `/admin/block-date/${schedule_canceled_id}`,
    headers: { "Content-Type": "application/json" },
    authorization: true,
  }).then(onApiSuccess, onApiError);
