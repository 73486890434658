import {
  TeamOutlined,
  SolutionOutlined,
  ScheduleOutlined,
  DashboardFilled,
  BellOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { isSuperAdmin } from "../../common/utils";
import i18n from "../../i18n";
import "./index.less";

const { Header, Content, Footer } = Layout;

class index extends Component {
  logout = () => {
    this.props.logout();
    this.props.history.push("/login");
  };

  render() {
    return (
      <Layout className="layout">
        <Header className="header">
          <div className="logo">
            <h4>Star Tutor {isSuperAdmin() ? "Admin" : "Staff"}</h4>
          </div>
          <Menu theme="dark" mode="horizontal">
            <Menu.Item key="1" icon={<DashboardFilled />}>
              <NavLink to="/admin/dashboard">Dashboard</NavLink>
            </Menu.Item>
            <Menu.Item key="2" icon={<TeamOutlined />}>
              <NavLink to="/admin/users">Users</NavLink>
            </Menu.Item>
            <Menu.Item key="3" icon={<SolutionOutlined />}>
              <NavLink to="/admin/teacher-schedules">Teacher Schedules</NavLink>
            </Menu.Item>

            {isSuperAdmin() && (
              <>
                <Menu.Item key="4" icon={<SolutionOutlined />}>
                  <NavLink to="/admin/teachers">Teachers</NavLink>
                </Menu.Item>
                <Menu.Item key="99" icon={<SolutionOutlined />}>
                  <NavLink to="/admin/subjects">Subjects</NavLink>
                </Menu.Item>
              </>
            )}
            <Menu.Item key="5" icon={<ScheduleOutlined />}>
              <NavLink to="/admin/payments">Payments</NavLink>
            </Menu.Item>
            {isSuperAdmin() && (
              <Menu.Item key="17" icon={<FileSyncOutlined />}>
                <NavLink to="/admin/receipts">Receipts</NavLink>
              </Menu.Item>
            )}
            <Menu.Item key="6" icon={<BellOutlined />}>
              <NavLink to="/admin/announcements">Announcements</NavLink>
            </Menu.Item>
            {isSuperAdmin() && (
              <Menu.Item key="7" icon={<TeamOutlined />}>
                <NavLink to="/admin/adminusers">Admins</NavLink>
              </Menu.Item>
            )}

            {/* <Menu.Item key="5" icon={<ScheduleOutlined />}>
              <NavLink to="/admin/free-trials">Free Trials</NavLink>
            </Menu.Item> */}
            <Menu.Item
              onClick={() => {
                this.logout();
              }}
            >
              Logout
            </Menu.Item>
          </Menu>
        </Header>

        <Content
          className={`site-layout-background content-container ${
            this.props.className ? this.props.className : ""
          }`}
        >
          {this.props.children}
        </Content>
        <Footer className="footer-container">
          <span className="copy-text">
            &copy; 2024{" "}
            <a
              href="https://startutor.my"
              target="_blank"
              rel="noopener noreferrer"
            >
              Star Tutor
            </a>
            . {i18n.t(`All Rights Reserved`)}.
          </span>
        </Footer>
      </Layout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
