import React, { Component } from "react";
import { Table, Space, Col, Row, Popconfirm } from "antd";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import moment from "moment";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
  };

  componentDidMount() {
    this.props.getAnnouncements();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
    this.props.getAnnouncements(dataIndex, selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getAnnouncements();
  };

  render() {
    const columns = [
      {
        width: "200px",
        title: "Date Created",
        dataIndex: "date_created",
        key: "date_created",
        ...this.getColumnSearchProps("date_created"),
        render: (_text, record) => (
          <p>{moment(record.date_created).format("DD MMM, YYYY hh:mm A")}</p>
        ),
      },
      {
        width: "100px",
        title: "Type",
        dataIndex: "announcement_type",
        key: "announcement_type",
        ...this.getColumnSearchProps("announcement_type"),
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        ellipsis: true,
        ...this.getColumnSearchProps("title"),
      },
      {
        width: "200px",
        title: "Action",
        key: "action",
        render: (_text, record) => (
          <Space size="middle">
            <NavLink to={`announcements/${record.announcement_id}`}>
              <Button type="link" style={{ border: "none" }}>
                View
              </Button>
            </NavLink>
            <Popconfirm
              title="Are you sure to delete this announcement?"
              onConfirm={async () => {
                await this.props.deleteAnnouncement(record.announcement_id);
                this.props.getAnnouncements();
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button danger type="primary" size="small">
                Delete
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ];
    let results = [];
    if (this.props.announcements.length > 0) {
      results = this.props.announcements.map((row, index) => ({
        ...row,
        key: row.schedule_id,
      }));
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Announcements</h4>
            <NavLink to={`/admin/announcement`}>
              <Button type="primary" className="btn-add">
                + Add Announcement
              </Button>
            </NavLink>

            {this.props.announcements &&
              this.props.announcements.length > 0 && (
                <CSVLink
                  data={this.props.announcements}
                  filename={"announcement.csv"}
                >
                  <Button className="btn-download" size="small">
                    Download CSV
                  </Button>
                </CSVLink>
              )}
          </Col>
        </Row>
        <Table columns={columns} dataSource={results} />
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    announcements: state.announcements,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
