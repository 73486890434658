import { Alert, Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const schoolTypes = [
  { title: "SK", id: "SK" },
  { title: "SJK(T)", id: "SJK(T)" },
  { title: "SJK(C)", id: "SJK(C)" },
  { title: "SMK", id: "SMK" },
  { title: "SMJK(C)", id: "SMJK(C)" },
  { title: "Private School", id: "Private School" },
];

const { Option } = Select;

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [user, setCurrentUser] = useState({});
  let [kid, setCurrentKid] = useState({});

  const userId = props.match.params.user_id;

  useEffect(() => {
    props
      .setCurrentUser(userId)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          setCurrentUser(resp.data.data.user);
          setCurrentKid(resp.data.data.kids[0]);
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  }, [userId, setCurrentUser]);

  let defaultValues = {};

  useEffect(() => {
    defaultValues = user;
    defaultValues["ic_number"] = kid.ic_number;
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    var formdata = {
      full_name: values.full_name,
      contact_number: values.contact_number,
      dad_contact: values.dad_contact,
      mom_contact: values.mom_contact,
      school_type: values.school_type,
      school_name: values.school_name,
      address: values.address,
      city: values.city,
      state: values.state,
      postcode: values.postcode,
      ic_number: values.ic_number,
    };

    props
      .updateUser(userId, formdata)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/users/" + userId);
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item
                name="full_name"
                label="Name"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Student Name"
                />
              </Form.Item>

              <Form.Item
                name="contact_number"
                label="Contact Number"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Contact Number"
                />
              </Form.Item>

              <Form.Item
                name="dad_contact"
                label="DAD Number"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="DAD Number"
                />
              </Form.Item>

              <Form.Item
                name="mom_contact"
                label="MOM Number"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="MOM Number"
                />
              </Form.Item>

              <Form.Item
                name="ic_number"
                label="IC Number"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="IC Number"
                />
              </Form.Item>
              <Row gutter={[20, 0]}>
                <Col md={8}>
                  <Form.Item
                    name="school_type"
                    label="School Type"
                    className="teacher-schedule-edit-label"
                  >
                    <Select
                      className="teacher-schedule-edit-input"
                      placeholder="School Type"
                    >
                      {schoolTypes.map((item) => (
                        <Option key={item.id} value={item.title}>
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={16}>
                  <Form.Item
                    name="school_name"
                    label="School Name"
                    className="teacher-schedule-edit-label"
                  >
                    <Input
                      className="teacher-schedule-edit-input"
                      placeholder="School Name"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="address"
                label="Address"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Address"
                />
              </Form.Item>

              <Form.Item
                name="city"
                label="City"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="City"
                />
              </Form.Item>

              <Form.Item
                name="state"
                label="State"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="State"
                />
              </Form.Item>

              <Form.Item
                name="postcode"
                label="Postcode"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Postcode"
                />
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Update
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    teacher: state.teacher,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
