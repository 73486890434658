import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "../src/locales/en/translation.json";
import translationBM from "../src/locales/bm/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  bm: {
    translation: translationBM,
  },
};
const detectionOptions = {
  order: [
    "cookie",
    "navigator",
    "localStorage",
    "subdomain",
    "queryString",
    "htmlTag",
    "path",
  ],
  lookupFromPathIndex: 0,
};
i18n
  .use(detector)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      lng: "bm",
      fallbackLng: "en", // use en if detected lng is not available
      debug: false,
      detection: detectionOptions,

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    (err, t) => {
      if (err) console.error(err);
    }
  );
export default i18n;
