import { Button, Col, Input, Row, Space, Table, Popconfirm } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { CSVLink } from "react-csv";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { isSuperAdmin } from "../../common/utils";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
  };
  componentDidMount() {
    this.props.getSubjects();
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getSubjects();
  };

  deleteSubject = (id) => {
    this.props
      .deleteSubject(id)
      .then((resp) => {
        this.props.getSubjects();
      })
      .catch((_err) => {});
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  columns = [
    {
      title: "Grade name",
      dataIndex: "grade_type",
      key: "grade_type",
      ...this.getColumnSearchProps("grade_type"),
    },
    {
      title: "Subject name",
      dataIndex: "subject_name",
      key: "subject_name",
      ...this.getColumnSearchProps("subject_name"),
      render: (_text, record) => (
        <p>
          {record.subject_name}{" "}
          {record.language_code ? `(${record.language_code})` : ""}
          {record.subject_type ? <strong> - {record.subject_type}</strong> : ""}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...this.getColumnSearchProps("status"),
    },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => (
        <Space size="middle">
          {record?.status !== "deactive" && (
            <Popconfirm
              title="Are you sure to delete this subject?"
              onConfirm={async () => {
                this.deleteSubject(record.subject_id);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small">
                Delete Subject
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  render() {
    let results = [];
    const { subjectsList } = this.props;
    if (subjectsList && subjectsList.length > 0) {
      results = subjectsList.map((row, index) => ({
        ...row,
        key: row.subject_id,
        sr_no: index + 1,
      }));
    }
    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Subjects</h4>

            {isSuperAdmin() && (
              <Button
                className="btn-add"
                onClick={() => this.props.history.push("/admin/subjects/add")}
                type="primary"
              >
                Add New Subject
              </Button>
            )}

            {subjectsList && subjectsList.length > 0 && (
              <CSVLink data={subjectsList} filename={"startutor-subjects.csv"}>
                <Button size="small" className="btn-download">
                  Download CSV
                </Button>
              </CSVLink>
            )}
            <p>
              Note: Newly <strong>added</strong> subject activate from next
              month registrations onwards, <strong>deactive</strong> subject
              deleted from next month registrations.
            </p>
          </Col>
        </Row>
        <Row>
          <Table dataSource={results} columns={this.columns} />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    subjectsList: state.subjects,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
