import { Alert, Button, Card, Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { setCurrentTeacherSchedule, class_note } = props;

  const scheduleId = props.match.params.schedule_id;
  const classNoteId = props.match.params.notes_id;

  let defaultValues = {
    video_link: "",
    file_link: "",
    class_date: "",
  };

  useEffect(() => {
    props.setCurrentClassNote(scheduleId, classNoteId);
  }, [scheduleId, classNoteId, setCurrentTeacherSchedule]);

  useEffect(() => {
    defaultValues = {
      ...defaultValues,
      ...class_note,
      class_date: moment(class_note.class_date),
    };
    form.setFieldsValue(defaultValues);
  }, [form, class_note]);

  const onFinish = (values) => {
    const data = {
      ...values,
    };

    props
      .editClassNote(classNoteId, { ...data })
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push(
            "/admin/teacher-schedules/" + scheduleId + "/class-notes"
          );
        } else if (resp.err && !resp.err.status) {
          setError(true);
          defaultValues = {
            ...defaultValues,
            ...class_note,
            class_date: moment(class_note.class_date),
          };
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        defaultValues = {
          ...defaultValues,
          ...class_note,
          class_date: moment(class_note.class_date),
        };
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item
                name="video_link"
                label="Video Link"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Video Link"
                />
              </Form.Item>
              <Form.Item
                name="file_link"
                label="File Link"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="File Link"
                />
              </Form.Item>
              <DatePicker
                defaultValue={moment(props.class_note.class_date)}
                style={{ marginBottom: 10 }}
                disabled
                placeholder={"Class Date"}
              />
              <Button htmlType="submit" type="primary" block>
                Edit Notes
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    class_note: state.class_note,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
