import {
  Button,
  Col,
  Input,
  Row,
  Space,
  Table,
  Menu,
  Dropdown,
  Switch,
  Alert,
} from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { CSVLink } from "react-csv";
import { NavLink } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
    toggleActiveLoading: false,
    errorMessage: "",
    isError: false,
  };
  componentDidMount() {
    this.props.getAdminUsers();
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleToggleActive = (record) => {
    this.setState({ toggleActiveLoading: true });
    this.props
      .updateAdminUserToggleActive(record.id)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          this.setState({ toggleActiveLoading: false });
          this.props.getAdminUsers();
        } else if (resp.err && !resp.err.status) {
          this.setState({
            toggleActiveLoading: false,
            isError: true,
            errorMessage: resp.err.message,
          });
        }
      })
      .catch((_err) => {
        this.setState({
          toggleActiveLoading: false,
          isError: true,
          errorMessage: "Something went wrong!",
        });
      });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getAdminUsers();
  };

  rendeActionMenu = (record) => (
    <Menu>
      <Menu.Item>
        <NavLink to={`adminusers/${record.id}`}>Edit</NavLink>
      </Menu.Item>
    </Menu>
  );

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  columns = [
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    //   ...this.getColumnSearchProps("date"),
    //   render: (_text, record) => (
    //     <p>{moment(record.date_created).format("DD MMM, YYYY hh:mm A")}</p>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => `${a.name}`.localeCompare(`${b.name}`),
      ...this.getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => `${a.email}`.localeCompare(`${b.email}`),
      ...this.getColumnSearchProps("email"),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a, b) => `${a.role}`.localeCompare(`${b.role}`),
      ...this.getColumnSearchProps("role"),
    },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (_text, record) => (
    //     <img style={{ maxWidth: 60 }} src={record.image}></img>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_text, record) => (
        <Space size="middle">
          <Switch
            onClick={() => this.handleToggleActive(record)}
            loading={this.state.toggleActiveLoading}
            checkedChildren="active"
            unCheckedChildren="deactive"
            checked={record.status === "active"}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={this.rendeActionMenu(record)}
            placement="bottomCenter"
          >
            <Button size="small">Actions</Button>
          </Dropdown>
        </Space>
      ),
    },
  ];
  onAlertClose = () => {
    this.setState({ isError: false });
  };
  render() {
    let results = [];
    const { adminusersList } = this.props;
    if (adminusersList && adminusersList.length > 0) {
      results = this.props.adminusersList.map((row, index) => ({
        ...row,
        key: row.id,
        sr_no: index + 1,
      }));
    }
    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Admin Users</h4>

            <Button
              onClick={() => this.props.history.push("/admin/addadminusers")}
              type="primary"
              className="btn-add"
            >
              Add New
            </Button>

            {adminusersList && adminusersList.length > 0 && (
              <CSVLink
                data={adminusersList}
                filename={"startutor-teachers.csv"}
              >
                <Button className="btn-download" size="small">
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Col>
        </Row>
        <Row>
          {this.state.isError && (
            <Alert
              message="Error"
              description={this.state.errorMessage}
              type="error"
              closable
              onClose={() => this.onAlertClose()}
            />
          )}
          <Table dataSource={results} columns={this.columns} />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    adminusersList: state.adminusers,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
