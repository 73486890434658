import LoginPage from "../containers/auth/index";
import NotFoundPage from "../containers/404/index";
import UsersPage from "../containers/users/index";
import EditSchedulePage from "../containers/users/edit_schedule";
import AdminUserPage from "../containers/admin_users/index";
import AdminUserEditPage from "../containers/admin_users/edit";
import AdminUserAddPage from "../containers/admin_users/add";

import SubjectPage from "../containers/subjects/index";
import SubjectAddPage from "../containers/subjects/add";

import TeachersPage from "../containers/teachers/index";
import TeacherEditPage from "../containers/teachers/edit";
import TeacherAddPage from "../containers/teachers/add";
import UserPage from "../containers/users/show";
import EditUserPage from "../containers/users/edit";
import DashboardPage from "../containers/dashboard/index";
import TeacherSchedulesPage from "../containers/teacher_schedules/index";
import TeacherScheduleEditPage from "../containers/teacher_schedules/edit";
import TeacherSchedulesAddPage from "../containers/teacher_schedules/add";
import TeacherScheduleAddClassNotePage from "../containers/teacher_schedules/class_note";
import AnnouncementsPage from "../containers/announcements/index";
import AddAnnouncementsPage from "../containers/announcements/add";
import EditAnnouncementsPage from "../containers/announcements/edit";

import PaymentsPage from "../containers/payments/index";
import PaymentsEditPage from "../containers/payments/edit";
import FreeTrialsPage from "../containers/free_trial/index";
import FreeTrialsEditPage from "../containers/free_trial/edit";
import ClassNotesPage from "../containers/class_notes/index";
import ClassNotesEditPage from "../containers/class_notes/edit";
import ReceiptListPage from "../containers/receipts/list";

import TeacherScheduleBlockDatesPage from "../containers/block_schedule_dates/index";
import TeacherScheduleAddBlockDatePage from "../containers/block_schedule_dates/add";
import TeacherScheduleBlockDatesEditPage from "../containers/block_schedule_dates/edit";

// routes for declare new pages
const routes = [
  { path: "/login", component: LoginPage, exact: true },
  { path: "/admin/dashboard", component: DashboardPage, exact: true },
  { path: "/admin/users", component: UsersPage, exact: true },
  { path: "/admin/adminusers", component: AdminUserPage, exact: true },
  { path: "/admin/addadminusers", component: AdminUserAddPage, exact: true },
  {
    path: "/admin/adminusers/:adminuser_id",
    component: AdminUserEditPage,
    exact: true,
  },
  { path: "/admin/subjects", component: SubjectPage, exact: true },
  { path: "/admin/subjects/add", component: SubjectAddPage, exact: true },
  { path: "/admin/teachers", component: TeachersPage, exact: true },
  { path: "/admin/teachers/add", component: TeacherAddPage, exact: true },
  {
    path: "/admin/teachers/:teacher_id",
    component: TeacherEditPage,
    exact: true,
  },
  { path: "/admin/users/:user_id", component: UserPage, exact: true },
  { path: "/admin/user/:user_id", component: EditUserPage, exact: true },
  {
    path: "/admin/user-schedule/:userId/:kidId",
    component: EditSchedulePage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules",
    component: TeacherSchedulesPage,
    exact: true,
  },
  {
    path: "/admin/add-teacher-schedules",
    component: TeacherSchedulesAddPage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules/:schedule_id",
    component: TeacherScheduleEditPage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules/:schedule_id/block-dates",
    component: TeacherScheduleBlockDatesPage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules/:schedule_id/block-date",
    component: TeacherScheduleAddBlockDatePage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules/:schedule_id/block-date/:block_date_id",
    component: TeacherScheduleBlockDatesEditPage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules/:schedule_id/class-notes",
    component: ClassNotesPage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules/:schedule_id/class-note",
    component: TeacherScheduleAddClassNotePage,
    exact: true,
  },
  {
    path: "/admin/teacher-schedules/:schedule_id/class-notes/:notes_id",
    component: ClassNotesEditPage,
    exact: true,
  },
  { path: "/admin/payments", component: PaymentsPage, exact: true },
  {
    path: "/admin/payments/:payment_id",
    component: PaymentsEditPage,
    exact: true,
  },
  { path: "/admin/announcements", component: AnnouncementsPage, exact: true },
  {
    path: "/admin/announcements/:announcement_id",
    component: EditAnnouncementsPage,
    exact: true,
  },
  {
    path: "/admin/announcement",
    component: AddAnnouncementsPage,
    exact: true,
  },
  { path: "/admin/receipts", component: ReceiptListPage, exact: true },
  { path: "/admin/free-trials", component: FreeTrialsPage, exact: true },
  {
    path: "/admin/free-trials/:free_trial_id",
    component: FreeTrialsEditPage,
    exact: true,
  },
  { path: "/", component: LoginPage, exact: true },
  { component: NotFoundPage },
];

export default routes;
