import React, { Component } from "react";
import { Table, Space, Col, Row, Dropdown, Menu, Popconfirm } from "antd";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { isSuperAdmin } from "../../common/utils";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
  };

  componentDidMount() {
    this.props.getTeacherSchedules();
  }

  deactiveTeacherScheduleHandle(schedule_id) {
    this.props
      .deactiveTeacherSchedule(schedule_id)
      .then((resp) => {
        this.props.getTeacherSchedules();
      })
      .catch((_err) => {});
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
    this.props.getTeacherSchedules(dataIndex, selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getTeacherSchedules();
  };

  render() {
    const menu = (record) => (
      <Menu>
        <Menu.Item>
          <NavLink
            target="_blank"
            to={`teacher-schedules/${record.schedule_id}`}
          >
            Edit Class link
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink
            target="_blank"
            to={`teacher-schedules/${record.schedule_id}/class-note`}
          >
            Add Class Note
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink
            target="_blank"
            to={`teacher-schedules/${record.schedule_id}/class-notes`}
          >
            Manage Class Notes
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink
            target="_blank"
            to={`teacher-schedules/${record.schedule_id}/block-date`}
          >
            Add Block date
          </NavLink>
        </Menu.Item>
        <Menu.Item>
          <NavLink
            target="_blank"
            to={`teacher-schedules/${record.schedule_id}/block-dates`}
          >
            Manage Block dates
          </NavLink>
        </Menu.Item>

        {isSuperAdmin() &&
          (record?.schedule_status !== "deactive" ? (
            <Menu.Item>
              <Popconfirm
                title="Are you sure to delete this class schedule from next month?"
                onConfirm={async () => {
                  this.deactiveTeacherScheduleHandle(record.schedule_id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" size="small">
                  Delete Schedule
                </Button>
              </Popconfirm>
            </Menu.Item>
          ) : (
            ``
          ))}
      </Menu>
    );

    const columns = [
      {
        title: "Grade",
        dataIndex: "grade_type",
        key: "grade_type",
        ...this.getColumnSearchProps("grade_type"),
      },
      {
        title: "Subject Name",
        dataIndex: "subject_name",
        key: "subject_name",
        ...this.getColumnSearchProps("subject_name"),
        render: (_text, record) => (
          <p>
            {record.subject_name}{" "}
            {record.language_code ? `(${record.language_code})` : ""}
            {record.subject_type && <strong>({record.subject_type})</strong>}
          </p>
        ),
      },
      {
        title: "Teacher Name",
        dataIndex: "teacher_name",
        key: "teacher_name",
        ...this.getColumnSearchProps("teacher_name"),
      },
      {
        title: "Day",
        dataIndex: "week_day",
        key: "week_day",
        ...this.getColumnSearchProps("week_day"),
      },
      {
        title: "Start Time",
        dataIndex: "start_time",
        key: "start_time",
        ...this.getColumnSearchProps("start_time"),
      },
      {
        title: "End Time",
        dataIndex: "end_time",
        key: "end_time",
        ...this.getColumnSearchProps("end_time"),
      },
      {
        title: "Schedule Status",
        dataIndex: "schedule_status",
        key: "schedule_status",
        ...this.getColumnSearchProps("schedule_status"),
      },
      {
        title: "Zoom Class Link",
        dataIndex: "zoom_class_link",
        key: "zoom_class_link",
        sorter: (a, b) =>
          a.zoom_class_link
            ? a.zoom_class_link.localeCompare(b.zoom_class_link)
            : 0,
      },
      {
        title: "Action",
        key: "action",
        render: (_text, record) => (
          <Space size="middle">
            <Dropdown
              overlay={menu(record)}
              placement="topCenter"
              trigger={["click"]}
            >
              <Button size="small">Actions</Button>
            </Dropdown>
          </Space>
        ),
      },
    ];
    let results = [];
    if (this.props.teacher_schedules.length > 0) {
      results = this.props.teacher_schedules.map((row, index) => ({
        ...row,
        key: row.schedule_id,
      }));
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Teacher Schedules</h4>
            {isSuperAdmin() && (
              <Button
                className="btn-add"
                onClick={() =>
                  this.props.history.push("/admin/add-teacher-schedules")
                }
                type="primary"
              >
                Add New Schedule
              </Button>
            )}

            {this.props.teacher_schedules &&
              this.props.teacher_schedules.length > 0 && (
                <CSVLink
                  data={this.props.teacher_schedules.map((ts) => {
                    return {
                      grade_type: ts.grade_type,
                      subject_name: ts.subject_name,
                      teacher_name: ts.teacher_name,
                      week_day: ts.week_day,
                      start_time: ts.start_time,
                      end_time: ts.end_time,
                      zoom_class_link: ts.zoom_class_link,
                    };
                  })}
                  filename={"teachers_schedules.csv"}
                >
                  <Button className="btn-download" size="small">
                    Download CSV
                  </Button>
                </CSVLink>
              )}

            <p>
              Note: Newly <strong>added</strong> schedule activate from next
              month registrations onwards, <strong>deactive</strong> schedule
              deleted from next month registrations.
            </p>
          </Col>
        </Row>
        <Table columns={columns} dataSource={results} />
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    teacher_schedules: state.teacher_schedules,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
