import { Button, Col, Input, Row, Space, Table, Menu, Dropdown } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { CSVLink } from "react-csv";
import { NavLink } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { getImage, isSuperAdmin } from "../../common/utils";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
  };
  componentDidMount() {
    this.props.getTeachers();
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getTeachers();
  };

  rendeActionMenu = (record) => (
    <Menu>
      <Menu.Item>
        <NavLink to={`teachers/${record.id}`}>Edit</NavLink>
      </Menu.Item>
    </Menu>
  );

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  columns = [
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    //   ...this.getColumnSearchProps("date"),
    //   render: (_text, record) => (
    //     <p>{moment(record.date_created).format("DD MMM, YYYY hh:mm A")}</p>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => `${a.name}`.localeCompare(`${b.name}`),
      ...this.getColumnSearchProps("name"),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (_text, record) => (
        <img style={{ maxWidth: 60 }} src={getImage(record.image)} alt="img" />
      ),
    },
    // {
    //   title: "Desc",
    //   dataIndex: "description",
    //   key: "description",
    //   render: (_text, record) => (
    //     <p>{record.description}</p>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={this.rendeActionMenu(record)}
            placement="bottomCenter"
          >
            <Button size="small">Actions</Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  render() {
    let results = [];
    const { teachersList } = this.props;
    if (teachersList && teachersList.length > 0) {
      results = this.props.teachersList.map((row, index) => ({
        ...row,
        key: row.id,
        sr_no: index + 1,
      }));
    }
    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Teachers</h4>

            {isSuperAdmin() && (
              <Button
                className="btn-add"
                onClick={() => this.props.history.push("/admin/teachers/add")}
                type="primary"
              >
                Add New Teacher
              </Button>
            )}

            {teachersList && teachersList.length > 0 && (
              <CSVLink data={teachersList} filename={"startutor-teachers.csv"}>
                <Button size="small" className="btn-download">
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Col>
        </Row>
        <Row>
          <Table dataSource={results} columns={this.columns} />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    teachersList: state.teachers,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
