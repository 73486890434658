import { Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const Add = (props) => {
  const announcementId = props.match.params.announcement_id;

  const { setCurrentAnnouncement, announcement } = props;

  useEffect(() => {
    setCurrentAnnouncement(announcementId);
    props.getGrades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementId, setCurrentAnnouncement]);

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={24}>
          <Card className="teacher-schedule-edit-card">
            <p>
              <strong>Type:</strong> {announcement.announcement_type}
            </p>
            <p>
              <strong>Title: </strong> {announcement.title}
            </p>
            <p>
              <strong>Description: </strong>
              <div
                dangerouslySetInnerHTML={{ __html: announcement.description }}
              ></div>
            </p>
            <div>
              <p>
                <strong>Selected Timetable: </strong>
              </p>

              {announcement &&
                announcement.schedules &&
                announcement.schedules.length > 0 &&
                announcement.schedules.map((val) => (
                  <p>
                    {val.subject_name} ({val.language_code}) -{" "}
                    <strong>({val.subject_type})</strong> - {val.teacher_name} -{" "}
                    {val.week_day} - {val.start_time} to {val.end_time}
                  </p>
                ))}
            </div>
            {announcement.media_file_name && (
              <a
                href={announcement.media_file_name}
                target="_blank"
                rel="noopener noreferrer"
              >
                View media
              </a>
            )}
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    grades: state.grades,
    schedules: state.schedules,
    announcement: state.announcement,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Add));
