import { types as T, make3 } from "./action_types";
import * as API from "../services/api";

// set route path
export function setRoute(data) {
  return {
    type: T.SET_ROUTE,
    data,
  };
}
export function getTimeTable(userId) {
  return {
    types: make3("GET_TIMETABLE"),
    promise: () => {
      return API.getTimeTable(userId).then((data) => data);
    },
  };
}
// Login Admin
export function loginAdmin(data) {
  return {
    types: make3("LOGIN"),
    promise: () => {
      return API.login(data).then((result) => result);
    },
  };
}

// Set Admin info
export function setAdminInfo(data) {
  return {
    type: T.SET_ADMININFO,
    data,
  };
}

// Get Users List
export function getUsers() {
  return {
    types: make3("GET_USERS"),
    promise: () => {
      return API.getUsers().then((result) => result);
    },
  };
}

export function getGrades() {
  return {
    types: make3("GET_GRADES"),
    promise: () => {
      return API.getGrades().then((result) => result);
    },
  };
}

export function getSubjects(grad_id = undefined) {
  return {
    types: make3("GET_SUBJECTS"),
    promise: () => {
      return API.getSubjects(grad_id).then((result) => result);
    },
  };
}

export function getSchedules(grade_id) {
  return {
    types: make3("GET_SCHEDULES"),
    promise: () => {
      return API.getSchedules(grade_id).then((result) => result);
    },
  };
}

export function deactiveTeacherSchedule(schedule_id) {
  return {
    types: make3("UPDATE_DEACTIVE_TEACHER_SCHEDULE"),
    promise: () => {
      return API.deactiveTeacherSchedule(schedule_id).then((result) => result);
    },
  };
}

export function addAnnouncement(data) {
  return {
    types: make3("ADD_ANNOUNCEMENT"),
    promise: () => {
      return API.addAnnouncement(data).then((result) => result);
    },
  };
}

export function addFile(file) {
  return {
    types: make3("ADD_FiLE"),
    promise: () => {
      return API.addFile(file).then((result) => result);
    },
  };
}

export function getTeacherSchedules(data_index = null, search = null) {
  if (search) {
    search = search.toLowerCase();
  }
  return {
    types: make3("GET_TEACHER_SCHEDULES"),
    promise: (n) => {
      return API.getTeacherSchedules(null, data_index, search).then(
        (result) => result
      );
    },
  };
}

export function addTeacherSchedule(data) {
  return {
    types: make3("ADD_TEACHER_SCHEDULE"),
    promise: (n) => {
      return API.addTeacherSchedule(data).then((result) => result);
    },
  };
}

export function getClassNotes(schedule_id, data_index = null, search = null) {
  if (search) {
    search = search.toLowerCase();
  }
  return {
    types: make3("GET_CLASS_NOTES"),
    promise: (n) => {
      return API.getClassNotes(schedule_id, null, data_index, search).then(
        (result) => result
      );
    },
  };
}

export function deleteClassNote(notes_id) {
  return {
    types: make3("DELETE_CLASS_NOTES"),
    promise: (n) => {
      return API.deleteClassNote(notes_id).then((result) => result);
    },
  };
}

export function deleteAnnouncement(announcement_id) {
  return {
    types: make3("DELETE_ANNOUNCEMENT"),
    promise: (n) => {
      return API.deleteAnnouncement(announcement_id).then((result) => result);
    },
  };
}

export function getDashboardStats() {
  return {
    types: make3("GET_DASHBOARD_STATS"),
    promise: () => {
      return API.getDashboardStats().then((result) => result);
    },
  };
}

export function getPayments(data_index = null, search = null) {
  if (search) {
    search = search.toLowerCase();
  }
  return {
    types: make3("GET_PAYMENTS"),
    promise: () => {
      return API.getPayments(null, data_index, search).then((result) => result);
    },
  };
}

export function getAnnouncements(data_index = null, search = null) {
  return {
    types: make3("GET_ANNOUNCEMENTS"),
    promise: () => {
      return API.getAnnouncements(null, data_index, search).then(
        (result) => result
      );
    },
  };
}

export function getFreeTrials(data_index = null, search = null) {
  return {
    types: make3("GET_FREE_TRIALS"),
    promise: () => {
      return API.getFreeTrials(null, data_index, search).then(
        (result) => result
      );
    },
  };
}

export function setCurrentTeacherSchedule(schedule_id) {
  return {
    types: make3("GET_TEACHER_SCHEDULE"),
    promise: () => {
      return API.getTeacherSchedules(schedule_id).then((result) => result);
    },
  };
}

export function setCurrentClassNote(schedule_id, notes_id) {
  return {
    types: make3("GET_CLASS_NOTE"),
    promise: () => {
      return API.getClassNotes(schedule_id, notes_id).then((result) => result);
    },
  };
}

export function setCurrentAnnouncement(announcement_id) {
  return {
    types: make3("GET_ANNOUNCEMENT"),
    promise: () => {
      return API.getAnnouncements(announcement_id).then((result) => result);
    },
  };
}

export function updateAnnouncement(announcement_id, data) {
  return {
    types: make3("UPDATE_ANNOUNCEMENT"),
    promise: () => {
      return API.updateAnnouncement(announcement_id, data).then(
        (result) => result
      );
    },
  };
}

export function editClassNote(notes_id, data) {
  return {
    types: make3("UPDATE_CLASS_NOTE"),
    promise: () => {
      return API.editClassNote(notes_id, data).then((result) => result);
    },
  };
}

export function updateTeacherSchedule(schedule_id, data) {
  return {
    types: make3("UPDATE_TEACHER_SCHEDULE"),
    promise: () => {
      return API.updateTeacherSchedule(schedule_id, data).then(
        (result) => result
      );
    },
  };
}

export function setCurrentPayment(payment_id) {
  return {
    types: make3("GET_PAYMENT"),
    promise: () => {
      return API.getPayments(payment_id).then((result) => result);
    },
  };
}

export function setCurrentFreeTrial(free_trial_id) {
  return {
    types: make3("GET_FREE_TRIAL"),
    promise: () => {
      return API.getFreeTrials(free_trial_id).then((result) => result);
    },
  };
}

export function setCurrentUser(user_id) {
  return {
    types: make3("GET_USER"),
    promise: () => {
      return API.getUser(user_id).then((result) => result);
    },
  };
}

export function deletePayment(transaction_id) {
  return {
    types: make3("DELETE_PAYMENT"),
    promise: () => {
      return API.deletePayment(transaction_id).then((result) => result);
    },
  };
}

export function approvePayment(transaction_id, notes) {
  return {
    types: make3("APPROVE_PAYMENT"),
    promise: () => {
      return API.approvePayment({ transaction_id, notes }).then(
        (result) => result
      );
    },
  };
}

export function rejectPayment(transaction_id, notes, is_action_require = 0) {
  return {
    types: make3("REJECT_PAYMENT"),
    promise: () => {
      return API.rejectPayment({
        transaction_id,
        notes,
        is_action_require,
      }).then((result) => result);
    },
  };
}

export function failPayment(transaction_id, notes, is_action_require = 0) {
  return {
    types: make3("FAIL_PAYMENT"),
    promise: () => {
      return API.failPayment({ transaction_id, notes, is_action_require }).then(
        (result) => result
      );
    },
  };
}

export function approveFreeTrial(free_trial_id, notes) {
  return {
    types: make3("APPROVE_FREE_TRIAL"),
    promise: () => {
      return API.approveFreeTrial({ free_trial_id, notes }).then(
        (result) => result
      );
    },
  };
}

export function rejectFreeTrial(free_trial_id) {
  return {
    types: make3("REJECT_FREE_TRIAL"),
    promise: () => {
      return API.rejectFreeTrial({ free_trial_id }).then((result) => result);
    },
  };
}

export function addClassNote(data) {
  return {
    types: make3("ADD_CLASS_NOTE"),
    promise: () => {
      return API.addClassNote(data).then((result) => result);
    },
  };
}

export function getTeachers() {
  return {
    types: make3("GET_TEACHERS"),
    promise: () => {
      return API.getTeachers().then((result) => result);
    },
  };
}

export function getTeacher(teacher_id) {
  return {
    types: make3("GET_TEACHER"),
    promise: () => {
      return API.getTeacher(teacher_id).then((result) => result);
    },
  };
}
export function addTeacher(data) {
  return {
    types: make3("ADD_TEACHER"),
    promise: () => {
      return API.addTeacher(data).then((result) => result);
    },
  };
}

export function updateTeacher(teacher_id, data) {
  return {
    types: make3("UPDATE_TEACHER"),
    promise: () => {
      return API.updateTeacher(teacher_id, data).then((result) => result);
    },
  };
}

export function getAdminUsers() {
  return {
    types: make3("GET_ADMIN_USERS"),
    promise: () => {
      return API.getAdminUsers().then((result) => result);
    },
  };
}

export function getAdminUser(adminuser_id) {
  return {
    types: make3("GET_ADMIN_USER"),
    promise: () => {
      return API.getAdminUser(adminuser_id).then((result) => result);
    },
  };
}

export function addAdminUser(adminuser_id, data) {
  return {
    types: make3("UPDATE_ADMIN_USER"),
    promise: () => {
      return API.addAdminUser(adminuser_id, data).then((result) => result);
    },
  };
}

export function updateAdminUser(adminuser_id, data) {
  return {
    types: make3("UPDATE_ADMIN_USER"),
    promise: () => {
      return API.updateAdminUser(adminuser_id, data).then((result) => result);
    },
  };
}

export function updateAdminUserToggleActive(adminuser_id) {
  return {
    types: make3("TOGGLE_ACTIVE_ADMIN_USER"),
    promise: () => {
      return API.updateAdminUserToggleActive(adminuser_id).then(
        (result) => result
      );
    },
  };
}

export function getReceipts(month = null) {
  return {
    types: make3("GET_RECEIPTS"),
    promise: (n) => {
      return API.getReceipts(month).then((result) => result);
    },
  };
}

export function updateUser(userId, data) {
  return {
    types: make3("UPDATE_USER"),
    promise: () => {
      return API.updateUser(userId, data).then((result) => result);
    },
  };
}

export function deleteUserAccount(data, userId = null) {
  return {
    types: make3("DELETE_USER"),
    promise: (n) => {
      return API.deleteUserAccount(userId, data).then((result) => result);
    },
  };
}
export function addSubject(data) {
  return {
    types: make3("ADD_SUBJECT"),
    promise: () => {
      return API.addSubject(data).then((result) => result);
    },
  };
}
export function deleteSubject(data) {
  return {
    types: make3("DELETE_SUBJECT"),
    promise: () => {
      return API.deleteSubject(data).then((result) => result);
    },
  };
}

export function getKidTimeTable(data) {
  return {
    types: make3("GET_USER_SCHEDULE"),
    promise: () => {
      return API.getKidTimeTable(data).then((result) => result);
    },
  };
}
export function updateUserSchedule(userId, data) {
  return {
    types: make3("UPDATE_USER_SCHEDULE"),
    promise: () => {
      return API.updateUserSchedule(userId, data).then((result) => result);
    },
  };
}

export function addClassBlockDate(data) {
  return {
    types: make3("ADD_CLASS_BLOCK_DATE"),
    promise: () => {
      return API.addClassBlockDate(data).then((result) => result);
    },
  };
}
export function getClassBlockDates(schedule_id) {
  return {
    types: make3("GET_CLASS_BLOCK_DATES"),
    promise: () => {
      return API.getClassBlockDates(schedule_id, null).then((result) => result);
    },
  };
}

export function setCurrentBlockDate(schedule_id, schedule_canceled_id) {
  return {
    types: make3("GET_CLASS_BLOCK_DATE"),
    promise: () => {
      return API.getClassBlockDates(schedule_id, schedule_canceled_id).then(
        (result) => result
      );
    },
  };
}

export function deleteClassBlockDate(schedule_canceled_id) {
  return {
    types: make3("DELETE_CLASS_BLOCK_DATE"),
    promise: () => {
      return API.deleteClassBlockDate(schedule_canceled_id).then(
        (result) => result
      );
    },
  };
}

export function editClassBlockDate(schedule_canceled_id, data) {
  return {
    types: make3("UPDATE_CLASS_BLOCK_DATE"),
    promise: () => {
      return API.editClassBlockDate(schedule_canceled_id, data).then(
        (result) => result
      );
    },
  };
}

// clear login data called when logout press
export function logout() {
  API.tokenStorage.remove();
  return {
    type: T.LOGOUT,
  };
}
