import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import { displaySubjectName } from "../../common/utils";

const { Option } = Select;

const Add = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [gradesList, setGrades] = useState([]);
  let [subjectsList, setSubjects] = useState([]);
  let [teachersList, setTeachers] = useState([]);
  let [teacherSchedule, setTeacherSchedule] = useState({ weekday: "MON" });

  const weekDayList = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

  let initTeacherSchedule = {
    gradeid: "",
    subjectid: "",
  };

  useEffect(() => {
    props
      .getGrades()
      .then((resp) => {
        setGrades(resp.data.data);
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });

    props
      .getTeachers()
      .then((resp) => {
        setTeachers(resp.data.data);
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  }, [teacherSchedule, setTeacherSchedule]);

  useEffect(() => {
    setIsBtnActive(true);
  }, [teacherSchedule]);

  const defaultValues = {
    ...initTeacherSchedule,
    button_text: teacherSchedule?.button_text || "",
    button_link: teacherSchedule?.button_link || "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onAlertClose = () => {
    setError(false);
  };

  const handleChangeGrade = (value) => {
    props
      .getSubjects(value)
      .then((resp) => {
        setSubjects(resp.data.data);
        setTeacherSchedule({
          ...teacherSchedule,
          subjectid: null,
          gradeid: value,
        });
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const handleChangeSubject = (value) => {
    setTeacherSchedule({ ...teacherSchedule, subjectid: value });
  };

  const handleChangeTeacher = (value) => {
    setTeacherSchedule({ ...teacherSchedule, teacherid: value });
  };

  const handleChangeWeekDay = (value) => {
    setTeacherSchedule({ ...teacherSchedule, weekday: value });
  };

  const onChangeStartTime = (time, timeString) => {
    setTeacherSchedule({ ...teacherSchedule, starttime: timeString });
  };

  const onChangeEndTime = (time, timeString) => {
    setTeacherSchedule({ ...teacherSchedule, endtime: timeString });
  };

  const updateForm = (e) => {
    const { value, id } = e.target;
    teacherSchedule[id] = value;
    setTeacherSchedule(teacherSchedule);
  };

  const onFinish = (values) => {
    if (!isBtnActive) {
      values.button_text = "";
      values.button_link = "";
    }

    const data = {
      _grade_id: teacherSchedule.gradeid,
      _subject_id: teacherSchedule.subjectid,
      _teacher_id: teacherSchedule.teacherid,
      week_day: teacherSchedule.weekday,
      start_time: teacherSchedule.starttime,
      end_time: teacherSchedule.endtime,
    };

    props
      .addTeacherSchedule(data)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/teacher-schedules");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item
                name="gradeid"
                label="Grade"
                className="teacher-schedule-edit-label"
              >
                <Input
                  type="hidden"
                  className="teacher-schedule-edit-input"
                  placeholder="Grade"
                  onChange={updateForm}
                />

                <Select
                  className="teacher-schedule-edit-input"
                  onChange={(value) => handleChangeGrade(value)}
                >
                  {gradesList?.map((grade) => (
                    <Option key={grade.grade_id} value={grade.grade_id}>
                      {grade.grade_type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="subjectid"
                label="Subject"
                className="teacher-schedule-edit-label"
              >
                <Input
                  type="hidden"
                  defaultValue=""
                  className="teacher-schedule-edit-input"
                  placeholder="Subject"
                  onChange={updateForm}
                />

                <Select
                  value={teacherSchedule?.subjectid}
                  onChange={(value) => handleChangeSubject(value)}
                >
                  {subjectsList?.map((subject) => (
                    <Option key={subject.subject_id} value={subject.subject_id}>
                      {displaySubjectName(subject)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="teacherid"
                label="Teacher"
                className="teacher-schedule-edit-label"
              >
                <Input
                  type="hidden"
                  defaultValue=""
                  className="teacher-schedule-edit-input"
                  placeholder="Teacher"
                  onChange={updateForm}
                />

                <Select
                  value={teacherSchedule?.teacherid}
                  onChange={(value) => handleChangeTeacher(value)}
                >
                  {teachersList?.map((teacher) => (
                    <Option key={teacher.id} value={teacher.id}>
                      {teacher.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="weekday"
                label="Week Day"
                className="teacher-schedule-edit-label"
              >
                <Input
                  type="hidden"
                  defaultValue="MON"
                  className="teacher-schedule-edit-input"
                  placeholder="Week Day"
                  onChange={updateForm}
                />

                <Select
                  value={teacherSchedule?.weekday}
                  onChange={(value) => handleChangeWeekDay(value)}
                >
                  {weekDayList?.map((weekDay) => (
                    <Option key={weekDay} value={weekDay}>
                      {weekDay}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="starttime"
                label="Start Time"
                className="teacher-schedule-edit-label"
              >
                <TimePicker
                  defaultValue={
                    teacherSchedule?.starttime
                      ? moment(teacherSchedule?.starttime, "hh:mm a")
                      : undefined
                  }
                  use12Hours
                  format="hh:mm a"
                  onChange={onChangeStartTime}
                />
              </Form.Item>

              <Form.Item
                name="endtime"
                label="End Time"
                className="teacher-schedule-edit-label"
              >
                <TimePicker
                  defaultValue={
                    teacherSchedule?.endtime
                      ? moment(teacherSchedule?.endtime, "hh:mm a")
                      : undefined
                  }
                  use12Hours
                  format="hh:mm a"
                  onChange={onChangeEndTime}
                />
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Add
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    //grades: state.grades,
    //subjects: state.subjects,
    //teachers: state.teachers,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Add));
