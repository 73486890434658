import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Upload,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const { Option } = Select;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [gradesList, setGrades] = useState([]);

  const defaultValues = {
    name: "",
    language: "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    var formdata = new FormData();
    const { grade_id, name, language, media_file_name, subject_type } = values;
    formdata.append("grade_id", grade_id);
    formdata.append("name", name);
    formdata.append("language", language);
    formdata.append("subject_type", subject_type);
    formdata.append(
      "image",
      media_file_name && media_file_name.length > 0
        ? media_file_name[0]["originFileObj"]
        : "default_teacher.png"
    );

    props
      .addSubject(formdata)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/subjects");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  useEffect(() => {
    props
      .getGrades()
      .then((resp) => {
        setGrades(resp.data.data);
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  }, []);

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            <h4>Add new subject</h4>
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item
                name="grade_id"
                label="Grade"
                className="teacher-schedule-edit-label"
                rules={[
                  {
                    required: true,
                    message: "Please select grade",
                  },
                ]}
              >
                <Select className="teacher-schedule-edit-input">
                  {gradesList?.map((grade) => (
                    <Option key={grade.grade_id} value={grade.grade_id}>
                      {grade.grade_type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="name"
                label="Name"
                className="teacher-schedule-edit-label"
                rules={[
                  {
                    required: true,
                    message: "Please enter subject name",
                  },
                ]}
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Subject Name"
                />
              </Form.Item>

              <Form.Item
                name="language"
                label="Subject Language (if any)"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Subject Language (if any)"
                />
              </Form.Item>

              <Form.Item
                name="subject_type"
                label="Subject Type"
                className="teacher-schedule-edit-label"
                rules={[
                  {
                    required: true,
                    message: "Please select subject type",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={"tutorial"}>Tutorial</Radio>
                  <Radio value={"intensive"}>Intensive</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="media_file_name"
                label="Subject Image"
                valuePropName="image"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: true,
                    message: "Please select image",
                  },
                ]}
              >
                <Upload name="file">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Save
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    teacher: state.teacher,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
