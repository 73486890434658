import React, { Component } from "react";
import { Table, Space, Tag, Col, Row } from "antd";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
import { getStatusColor } from "../../common/utils";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
  };

  componentDidMount() {
    const paymentId = this.props.location.search.split("=");
    if (paymentId[0] === "?status")
      this.props.getPayments("payment_status", paymentId[1]);
    else this.props.getPayments();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
    //this.props.getPayments(dataIndex, selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getPayments();
  };

  render() {
    const columns = [
      {
        width: "200px",
        title: "Date",
        dataIndex: "date",
        key: "date",
        ...this.getColumnSearchProps("date"),
        render: (_text, record) => (
          <p>{moment(record.date).format("DD MMM, YYYY hh:mm A")}</p>
        ),
      },
      {
        width: "100px",
        title: "Action",
        key: "action",
        render: (_text, record) => (
          <Space size="middle">
            <NavLink
              target="_blank"
              to={`/admin/payments/${record.transaction_id}`}
            >
              View
            </NavLink>
          </Space>
        ),
      },
      {
        width: "120px",
        title: "Grade",
        dataIndex: "grade_type",
        key: "grade_type",
        ...this.getColumnSearchProps("grade_type"),
      },
      {
        width: "220px",
        title: "Student Name",
        dataIndex: "student_name",
        key: "student_name",
        sorter: (a, b) => a.student_name.localeCompare(b.student_name),
        ...this.getColumnSearchProps("student_name"),
      },
      {
        width: "200px",
        title: "Payment Month",
        dataIndex: "payment_month",
        key: "payment_month",
        sorter: (a, b) => a.payment_month.localeCompare(b.payment_month),
        ...this.getColumnSearchProps("payment_month"),
      },
      {
        width: "120px",
        title: "Payment Type",
        dataIndex: "payment_type",
        key: "payment_type",
        ...this.getColumnSearchProps("payment_type"),
      },
      {
        width: "120px",
        title: "Invoice No",
        dataIndex: "invoice_number",
        key: "invoice_number",
        ...this.getColumnSearchProps("invoice_number"),
      },
      {
        width: "120px",
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "payment_status",
        ...this.getColumnSearchProps("payment_status"),
        render: (_text, record) => (
          <Space size="middle">
            <Tag color={getStatusColor(record.payment_status)}>
              {record.payment_status || ""}
            </Tag>
          </Space>
        ),
      },
      {
        width: "150px",
        title: "Action By",
        dataIndex: "approved_by",
        key: "approved_by",
        ...this.getColumnSearchProps("approved_by"),
      },
      {
        width: "120px",
        title: "Total Price",
        dataIndex: "total_price",
        key: "total_price",
        ...this.getColumnSearchProps("total_price"),
      },
      {
        width: "120px",
        title: "Contact",
        dataIndex: "contact_number",
        key: "contact_number",
        ...this.getColumnSearchProps("contact_number"),
      },
      {
        title: "Email Id",
        dataIndex: "u_email_id",
        key: "u_email_id",
        ...this.getColumnSearchProps("u_email_id"),
      },
    ];
    let results = [];
    if (this.props.payments.length > 0) {
      results = this.props.payments.map((row, index) => ({
        ...row,
        price_per_subject: !!row.price_per_subject
          ? "RM " + row.price_per_subject
          : "-",
        total_price: !!row.total_price ? "RM " + row.total_price : "-",
        key: row.transaction_id,
        is_active: row.is_active === 1 ? "Yes" : "No",
      }));
    }

    return (
      <BasicLayout className="page-payments">
        <Row className="section-title">
          <Col md={24}>
            <h4>Payments</h4>

            {this.props.payments && this.props.payments.length > 0 && (
              <CSVLink
                data={this.props.payments.map((p) => {
                  return {
                    transaction_id: p.transaction_id,
                    date: p.date,
                    grade: p.grade_type,
                    student_name: p.student_name,
                    contact_number: p.contact_number,
                    dad_contact: p.dad_contact,
                    mom_contact: p.mom_contact,
                    email_id: p.u_email_id,
                    payment_type: p.payment_type,
                    invoice_number: p.invoice_number,
                    package_type: p.package_type,
                    subject_names: p.subject_names,
                    payment_status: p.payment_status,
                    notes: p.notes,
                    price_per_subject: !!p.price_per_subject
                      ? p.price_per_subject
                      : "",
                    no_of_subject: p.no_of_subject,
                    total_price: !!p.total_price ? p.total_price : "",
                    dob: p.dob,
                    gender: p.gender,
                    ic_number: p.ic_number,
                    school_name: p.school_name,
                    address: p.address,
                    postcode: p.postcode,
                    state: p.state,
                    city: p.city,
                    action_by: p.approved_by,
                  };
                })}
                filename={"payment-reports.csv"}
              >
                <Button className="btn-download" size="small">
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Col>
        </Row>
        <Table columns={columns} dataSource={results} />
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    payments: state.payments,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
