import { Alert, Button, Card, Col, Form, Input, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const { TextArea } = Input;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [teacher] = useState("");

  useEffect(() => {
    setIsBtnActive(true);
  }, [teacher]);

  const defaultValues = {
    name: teacher?.name || "",
    description: teacher?.description || "",
    image: teacher?.image || "default_teacher.png",
    is_active: teacher?.is_active === 1 ? true : false,
    button_text: teacher?.button_text || "",
    button_link: teacher?.button_link || "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    if (!isBtnActive) {
      values.button_text = "";
      values.button_link = "";
    }

    var formdata = new FormData();

    formdata.append("name", values.name);
    formdata.append("description", values.description);
    formdata.append(
      "image",
      values.media_file_name && values.media_file_name.length > 0
        ? values.media_file_name[0]["originFileObj"]
        : "default_teacher.png"
    );

    props
      .addTeacher(formdata)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/teachers");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item
                name="name"
                label="Name"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Teacher Name"
                />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                className="teacher-schedule-edit-label"
              >
                <TextArea
                  className="teacher-schedule-edit-input"
                  placeholder="Description"
                  rows={5}
                />
              </Form.Item>
              <Form.Item
                name="media_file_name"
                label="Photo"
                valuePropName="image"
                getValueFromEvent={normFile}
              >
                <Upload name="file">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Register
              </Button>
            </Form>
          </Card>
        </Col>
        {/* <Col className="teacher-schedule-edit-container" span={4} style={{marginLeft:10}}>
          <Card className="teacher-schedule-edit-card">
          <Image
              preview={false}
              src={teacher.image}
              width={"100%"}
              className="switch"
            />
          </Card>
        </Col> */}
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    teacher: state.teacher,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
