import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Radio,
  Upload,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import FormData from "form-data";
const { TextArea } = Input;
const { Option } = Select;

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const Add = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [, setAnnouncementType] = useState("all");
  const [, setMediaType] = useState("none");

  let defaultValues = {
    announcement_type: "all",
    announcement_data: [],
    file_link: "",
    title: "",
    description: "",
    media_type: "none",
    media_file_name: "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
    props.getGrades();
  }, [form]);

  const onValuesChange = (changedValues, allValues) => {
    if (!!changedValues.announcement_type)
      setAnnouncementType(changedValues.announcement_type);
    if (!!changedValues.media_type) setMediaType(changedValues.media_type);
  };

  const onFinish = (values) => {
    var formdata = new FormData();
    formdata.append("announcement_type", values.announcement_type);
    formdata.append(
      "announcement_data",
      JSON.stringify(values.announcement_data)
    );
    formdata.append("title", values.title);
    formdata.append("description", values.description);
    formdata.append("media_type", values.media_type);
    if (values.media_type !== "none")
      formdata.append("file", values.media_file_name[0]["originFileObj"]);

    props
      .addAnnouncement(formdata)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/announcements");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  const getSubjectName = (schedule) => {
    var subjectName = schedule.subject_name;
    const {
      language_code,
      subject_type,
      teacher_name,
      week_day,
      start_time,
      end_time,
    } = schedule;
    if (language_code !== "") {
      subjectName += ` (${language_code})`;
    }
    if (subject_type !== "") {
      subjectName += ` - (${subject_type})`;
    }

    var name = `${subjectName} - ${teacher_name} - ${week_day} - ${start_time} to ${end_time} `;
    return name;
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={18}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
              onValuesChange={onValuesChange}
            >
              <Form.Item
                name="announcement_type"
                label="Announcement Type"
                className="teacher-schedule-edit-label"
              >
                <Radio.Group>
                  <Radio value={"all"}>All User</Radio>
                  <Radio value={"specific"}>Specific Users</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="grade_id"
                label="Select Garde"
                style={
                  form.getFieldValue("announcement_type") !== "specific"
                    ? { display: "none" }
                    : {}
                }
                rules={[
                  {
                    required:
                      form.getFieldValue("announcement_type") === "specific",
                    message: "Please select grade",
                  },
                ]}
              >
                <Select
                  placeholder="Grade"
                  onChange={(value) => props.getSchedules(value)}
                >
                  {props.grades.map((grade) => (
                    <Option key={grade.grade_id} value={grade.grade_id}>
                      {grade.grade_type}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="announcement_data"
                label="Select teacher's schedule"
                style={
                  form.getFieldValue("announcement_type") !== "specific"
                    ? { display: "none" }
                    : {}
                }
                rules={[
                  {
                    required:
                      form.getFieldValue("announcement_type") === "specific",
                    message: "Please select schedule",
                  },
                ]}
              >
                <Select mode="multiple" placeholder="Please select teachers">
                  {props.schedules.map((schedule) => (
                    <Option
                      key={schedule.schedule_id}
                      value={schedule.schedule_id}
                    >
                      {getSubjectName(schedule)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="title"
                label="Title"
                className="teacher-schedule-edit-label"
                rules={[
                  {
                    required: true,
                    message: "Please enter title",
                  },
                ]}
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Title"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="teacher-schedule-edit-label"
                rules={[
                  {
                    required: true,
                    message: "Please enter description",
                  },
                ]}
              >
                <TextArea
                  className="teacher-schedule-edit-input"
                  rows={4}
                  style={{ marginBottom: 10 }}
                />
              </Form.Item>
              <Form.Item
                name="media_type"
                label="Media Type"
                className="teacher-schedule-edit-label"
              >
                <Radio.Group>
                  <Radio value={"none"}>None</Radio>
                  <Radio value={"image"}>Image</Radio>
                  <Radio value={"video"}>Video</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="media_file_name"
                label="Image / Video"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                style={
                  form.getFieldValue("media_type") === "none"
                    ? { display: "none" }
                    : {}
                }
                rules={[
                  {
                    required: form.getFieldValue("media_type") !== "none",
                    message: "Please upload file",
                  },
                ]}
              >
                <Upload name="file">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
              <Button htmlType="submit" type="primary" block>
                Send Announcement
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    grades: state.grades,
    schedules: state.schedules,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Add));
