import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Image,
  Popconfirm,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import {
  getImageUrl,
  getStatusColor,
  isSuperAdmin,
  renderWhatsAppLink,
} from "../../common/utils";

const { TextArea } = Input;

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [, setIsBtnActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [notes, setNotes] = useState("");
  const [visibleA, setVisibleA] = React.useState(false);
  const [visibleR, setVisibleR] = React.useState(false);
  const [visibleF, setVisibleF] = React.useState(false);
  const [resubmit, setResubmit] = React.useState(false);

  const { setCurrentPayment, payment } = props;

  const paymentId = props.match.params.payment_id;

  useEffect(() => {
    setCurrentPayment(paymentId);
  }, [paymentId, setCurrentPayment]);

  useEffect(() => {
    setIsBtnActive(true);
    //setNotes(payment.notes || "");
    setResubmit((payment.is_action_require === 1 ? true : false) || false);
  }, [payment]);

  useEffect(() => {
    // form.setFieldsValue(defaultValues);
  }, [form]);

  const onDelete = (event) => {
    props
      .deletePayment(paymentId)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/payments");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onApprove = (event) => {
    props
      .approvePayment(paymentId, notes)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/payments");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onReject = (event) => {
    if (notes === "") {
      setError(true);
      setErrorMessage("Note is required!");
    }

    var needToResubmit = 1;
    props
      .rejectPayment(paymentId, notes, needToResubmit)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/payments");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onFail = (event) => {
    if (notes === "") {
      setError(true);
      setErrorMessage("Note is required!");
    }
    props
      .failPayment(paymentId, notes, resubmit ? 1 : 0)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/payments");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  const handleNoteLogs = (value) => {
    const search = "|";

    let parts = [];

    parts = value?.split(search);

    let values = "";

    if (parts && parts.length > 0) {
      for (let index = 0; index < parts.length; index++) {
        const i = parts[index];
        let iParts = i?.split("#");
        if (iParts) {
          let iStr = `Payment Status: ${iParts[1]} \n Note: ${iParts[0]} \n Date: ${iParts[2]}`;
          if (values) {
            values = values + "\n -------------------------------- \n" + iStr;
          } else {
            values = iStr;
          }
        }
      }
    }

    return values;
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="payment-edit-container" span={12}>
          <Card className="payment-edit-card">
            <Card
              className="payment-edit-card"
              style={{ whiteSpace: "pre-wrap" }}
            >
              <p>
                <b>Date:</b>{" "}
                {moment(payment.date).format("DD MMM, YYYY hh:mm A")}
              </p>
              <p>
                <b>Payment ID:</b> {payment.transaction_id}
              </p>
              <p>
                <b>Payment Status: </b>
                <Tag color={getStatusColor(payment.payment_status)}>
                  {payment.payment_status || ""}
                </Tag>
              </p>
              <p>
                <b>Payment Mode:</b> {payment.payment_type}
              </p>
              {payment.invoice_number && (
                <p>
                  <b>Invoice Number:</b> {payment.invoice_number}
                </p>
              )}
              {payment.error_message && (
                <p>
                  <b>Payment Note:</b> {payment.error_message}
                </p>
              )}
              <p>
                <b>Student Name:</b> {payment.student_name}
              </p>
              <p>
                <b>Student Email:</b> {payment.u_email_id}
              </p>
              <p>
                <b>Student Contact Number:</b>{" "}
                {renderWhatsAppLink(payment.contact_number)}
              </p>
              <p>
                <b>Dad Number:</b> {renderWhatsAppLink(payment.dad_contact)}
              </p>
              <p>
                <b>Mom Number:</b> {renderWhatsAppLink(payment.mom_contact)}
              </p>
              <p>
                <b>Grade:</b> {payment.grade_type}
              </p>
              <p>
                <b>Package Type:</b> {payment.package_type}
              </p>
              <p>
                <b>Payment Month:</b> {payment.payment_month}
              </p>
              <p>
                <b>Price per Subject:</b> RM {payment.price_per_subject}
              </p>
              <p>
                <b>Number of Selected Subjects:</b> {payment.no_of_subject}
              </p>
              <p>
                <b>Total Price:</b> RM {payment.total_price}
              </p>
              <p>
                <b>Subject Names:</b> {payment.subject_names}
              </p>
              <p>
                <b>Notes:</b> {payment.notes}
              </p>
              {payment.last_action_date && (
                <p>
                  <b>Last Action Date:</b>
                  {moment(payment.last_action_date).format(
                    "DD MMM, YYYY hh:mm A"
                  )}
                </p>
              )}
              <p>
                <b>All Notes:</b> <br /> {handleNoteLogs(payment.notes_logs)}
              </p>
              {payment.invoice_url && (
                <a
                  href={payment.invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button type="primary">Download Receipt</Button>
                </a>
              )}
            </Card>
          </Card>
        </Col>
        <Col className="payment-edit-container" span={12}>
          <Card className="payment-edit-card">
            <Image
              src={getImageUrl(payment?.payment_image)}
              preview={{
                src: getImageUrl(payment?.payment_image),
              }}
              width={"100%"}
              style={{ maxWidth: 500 }}
              className="switch"
            />

            <Row>
              {!!payment && payment.payment_status === "pending" && (
                <TextArea
                  rows={4}
                  value={notes}
                  onChange={(text) => {
                    setNotes(text.target.value);
                  }}
                  style={{ marginBottom: 10 }}
                />
              )}
              {/* {!!payment && payment.payment_status !== "success" && (
                <Col
                  className="payment-edit-container"
                  style={{ margin: "10px 0" }}
                  span={24}
                >
                  <Checkbox
                    onChange={(e) => setResubmit(e.target.checked)}
                    checked={resubmit}
                  >
                    Need to resubmit image ?
                  </Checkbox>
                </Col>
              )} */}

              {isSuperAdmin() && (
                <>
                  {!!payment &&
                    (payment.payment_status === "success" ||
                      payment.payment_status === "payment-success") && (
                      <Col className="payment-edit-container" span={6}>
                        <Popconfirm
                          title="Are you sure you want to delete this payment?"
                          onConfirm={onDelete}
                        >
                          <Button
                            style={{
                              backgroundColor: "#dc3545",
                              padding: 10,
                              border: "none",
                            }}
                            htmlType="submit"
                            type="primary"
                            block
                          >
                            Delete Payment
                          </Button>
                        </Popconfirm>
                      </Col>
                    )}
                </>
              )}

              {!!payment && payment.payment_status === "pending" && (
                <Col className="payment-edit-container" span={6}>
                  <Popconfirm
                    title="Are you sure you want to approve this payment?"
                    visible={visibleA}
                    onConfirm={() => onApprove()}
                    onCancel={() => setVisibleA(false)}
                  >
                    <Button
                      style={{
                        backgroundColor: "#28a745",
                        padding: 10,
                        border: "none",
                      }}
                      htmlType="submit"
                      type="primary"
                      onClick={() => {
                        setVisibleA(true);
                        setVisibleR(false);
                        setVisibleF(false);
                      }}
                      block
                      disabled={resubmit}
                    >
                      Approve
                    </Button>
                  </Popconfirm>
                </Col>
              )}

              <Col className="payment-edit-container" span={2}></Col>
              {!!payment &&
                payment.payment_status === "pending" &&
                payment.payment_status !== "rejected" && (
                  <Col className="payment-edit-container" span={6}>
                    <Popconfirm
                      title="Are you sure you want to reject this payment?"
                      visible={visibleR}
                      onConfirm={onReject}
                      onCancel={() => setVisibleR(false)}
                    >
                      <Button
                        style={{
                          backgroundColor: "#ffc107",
                          padding: 10,
                          border: "none",
                        }}
                        htmlType="submit"
                        type="primary"
                        onClick={() => {
                          setVisibleR(true);
                          setVisibleA(false);
                          setVisibleF(false);
                        }}
                        block
                      >
                        Reject
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
              <Col className="payment-edit-container" span={2}></Col>
              {!!payment &&
                payment.payment_status === "pending" &&
                payment.payment_status !== "failed" && (
                  <Col className="payment-edit-container" span={6}>
                    <Popconfirm
                      title="Are you sure you want to mark as fail this payment?"
                      visible={visibleF}
                      onConfirm={onFail}
                      onCancel={() => setVisibleF(false)}
                    >
                      <Button
                        style={{
                          backgroundColor: "#dc3545",
                          padding: 10,
                          border: "none",
                        }}
                        htmlType="submit"
                        type="primary"
                        disabled={resubmit}
                        onClick={() => {
                          setVisibleF(true);
                          setVisibleR(false);
                          setVisibleA(false);
                        }}
                        block
                      >
                        Mark As Fail
                      </Button>
                    </Popconfirm>
                  </Col>
                )}
            </Row>

            <Row>
              {isError && (
                <Alert
                  message="Error"
                  description={errorMessage}
                  type="error"
                  closable
                  onClose={onAlertClose}
                />
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    payment: state.payment,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
