const dev = {
  API_URL: "http://localhost:5555",
  API_KEY: "OGXDVwlBcFBGZ4NYzjH8aipE8k2Lgz14clBlz20h",
};

const stage = {
  API_URL: "https://apistage.startutor.my/api",
  API_KEY: "OGXDVwlBcFBGZ4NYzjH8aipE8k2Lgz14clBlz20h",
};

const prod = {
  API_URL: "https://apiv1.startutor.my/api",
  API_KEY: "OGXDVwlBcFBGZ4NYzjH8aipE8k2Lgz14clBlz20h",
};

const config =
  process.env.REACT_APP_ENV === "PROD"
    ? prod
    : process.env.REACT_APP_ENV === "STAGE"
    ? stage
    : dev;

export default {
  ...config,
};
