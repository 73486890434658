import React, { Component } from "react";
import { Table, Space, Col, Row, Popconfirm } from "antd";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import moment from "moment";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
    visible: false,
    record_id: -1,
  };

  componentDidMount() {
    this.props.getClassNotes(this.props.match.params.schedule_id);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
    this.props.getClassNotes(
      this.props.match.params.schedule_id,
      dataIndex,
      selectedKeys[0]
    );
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getClassNotes(this.props.match.params.schedule_id);
  };

  onDelete = async () => {
    await this.props.deleteClassNote(this.state.record_id);
    this.props.getClassNotes(this.props.match.params.schedule_id);
  };

  render() {
    const columns = [
      {
        title: "Class Date",
        width: "130px",
        dataIndex: "class_date",
        key: "class_date",
        ...this.getColumnSearchProps("class_date"),
      },
      {
        title: "Video Link",
        ellipsis: true,
        width: "300px",
        dataIndex: "video_link",
        key: "video_link",
        ...this.getColumnSearchProps("video_link"),
      },
      {
        title: "File Link",
        ellipsis: true,
        width: "300px",
        dataIndex: "file_link",
        key: "file_link",
        ...this.getColumnSearchProps("file_link"),
      },
      {
        title: "Action",
        key: "action",
        render: (_text, record) => (
          <Space size="middle">
            <Space size="middle">
              <NavLink to={`class-notes/${record.notes_id}`}>Edit</NavLink>
              <Popconfirm
                title="Are you sure you want to delete?"
                visible={
                  this.state.record_id === record.notes_id && this.state.visible
                }
                onConfirm={this.onDelete}
                onCancel={() => this.setState({ visible: false })}
              >
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      visible: true,
                      record_id: record.notes_id,
                    });
                  }}
                  danger
                >
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          </Space>
        ),
      },
    ];
    let results = [];
    if (this.props.class_notes.length > 0) {
      results = this.props.class_notes.map((row, index) => ({
        ...row,
        class_date: moment(row.class_date).format("YYYY-MM-DD"),
        key: row.schedule_id,
      }));
    }

    return (
      <BasicLayout>
        <Row>
          <Col md={20}>
            <h4>Class Notes</h4>
          </Col>
          <Col md={4}>
            {this.props.class_notes && this.props.class_notes.length > 0 && (
              <CSVLink
                data={this.props.class_notes}
                filename={"class_notes.csv"}
              >
                <Button type="primary" size="small">
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Col>
        </Row>
        <Table columns={columns} dataSource={results} />
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    class_notes: state.class_notes,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
