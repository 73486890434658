import { Button, Card, Col, Form, Input, Row, List, Popconfirm } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import { renderWhatsAppLink, isSuperAdmin } from "../../common/utils";
import { notifySuccess, notifyError } from "../../common/notification";

const { TextArea } = Input;

const Edit = (props) => {
  const { history } = props;
  const [form] = Form.useForm();
  const [notes, setNotes] = useState("");
  const { setCurrentUser, user, getTimeTable, deleteUserAccount } = props;
  const timetable = props.timetable;
  const userId = props.match.params.user_id;
  const [listLoading, setListLoading] = React.useState(false);

  useEffect(() => {
    setListLoading(true);
    setCurrentUser(userId).then(setListLoading(false));
  }, [userId, setCurrentUser]);

  useEffect(() => {
    getTimeTable(userId);
  }, [userId, actions.getTimeTable]);

  useEffect(() => {
    // form.setFieldsValue(defaultValues);
  }, [form]);

  const deleteUser = async () => {
    if (notes && notes !== "") {
      var data = { note: notes };
      deleteUserAccount(data, userId).then((res) => {
        if (res && res.data) {
          if (res?.data?.status) {
            notifySuccess(res?.data?.message);
            history.push("/admin/users");
          } else {
            notifyError(res?.data?.message);
          }
        }
      });
    } else {
      alert("Please enter note!");
    }
  };

  const getWeekDaySchedules = (item) => {
    let sch_data = [];
    // foe each kid
    timetable.forEach((val) => {
      let data = {};
      data.kid_id = val?.kid_id;
      let allSchedules = [];
      val.schedule.forEach((sch) => {
        sch.schedules.forEach((val) =>
          allSchedules.push(
            `${val.subject_name} - ${val.subject_type} - ${val.teacher_name} - ${sch.week_day} - ${val.start_time} to ${val.end_time}`
          )
        );
      });
      data.schedules = allSchedules;
      sch_data.push(data);
    });
    return sch_data;
  };
  return (
    <BasicLayout>
      <Row gutter={[10, 10]}>
        <Col className="payment-edit-container" span={12}>
          <Card className="payment-edit-card">
            <Card
              className="payment-edit-card account-info"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {!!user?.user && <h4>{user?.user.full_name || ""}</h4>}
              <b>Email:</b>
              {user?.user?.u_email_id || ""}
              <br />
              <b>Contact Number:</b>{" "}
              {renderWhatsAppLink(user?.user?.contact_number || "")}
              <br />
              <b>DAD Contact Number:</b>{" "}
              {renderWhatsAppLink(user?.user?.dad_contact || "")}
              <br />
              <b>MOM Contact Number:</b>{" "}
              {renderWhatsAppLink(user?.user?.mom_contact || "")}
              <br />
              <b>School Name:</b>
              {user?.user?.school_type ||
                "" + " " + user?.user?.school_name ||
                ""}
              <br />
              <b>Grade:</b>
              {user?.user?.grade_name || ""}
              <br />
              <b>Address:</b>
              {user?.user?.address || ""}
              <br />
              <b>City:</b>
              {user?.user?.city || ""}
              <br />
              <b>State:</b>
              {user?.user?.state || ""}
              <br />
              <b>Postcode:</b>
              {user?.user?.postcode || ""}
              <br />
              {!!user.kids &&
                user?.kids.map((kid, index) => (
                  <div key={index}>
                    <b>Date of Birth:</b>
                    {kid?.dob || ""}
                    <br />
                    <b>Gender:</b>
                    {kid?.gender || ""}
                    <br />
                    <b>IC Number:</b>
                    {kid?.ic_number || ""}
                    <br />
                    <b>Account Status:</b>
                    {kid?.status || ""}
                    <br />
                    <hr />
                    <b>Account Created Date :</b>
                    {moment(kid?.date_created).format("DD MMM, YYYY hh:mm A")}

                    <br />
                    {kid.package_start_date && (
                      <p>
                        <b>Package Start Date:</b>
                        {moment(kid.package_start_date).format(
                          "DD MMM, YYYY"
                        ) || ""}
                      </p>
                    )}
                    {kid.package_exp_date && (
                      <p>
                        <b>Package Expiration Date:</b>
                        {moment(kid.package_exp_date).format("DD MMM, YYYY") ||
                          ""}
                      </p>
                    )}
                    <hr />
                    {isSuperAdmin() && (
                      <NavLink to={`../user/${userId}`}>
                        <Button type="warning">Edit User</Button>
                      </NavLink>
                    )}
                    <br />
                    <br />
                    {timetable.find((v) => v.kid_id === kid.kid_id) && (
                      <>
                        <b>Current Schedule:</b> <br />
                        <List
                          header={null}
                          footer={null}
                          bordered
                          loading={listLoading}
                          dataSource={
                            getWeekDaySchedules().find(
                              (val) => val.kid_id === kid.kid_id
                            )?.schedules
                          }
                          renderItem={(item) => (
                            <List.Item className="payment-list-item">
                              {item}
                            </List.Item>
                          )}
                        />
                        <br />
                        {isSuperAdmin() && (
                          <NavLink
                            to={`../user-schedule/${kid._user_id}/${kid.kid_id}`}
                          >
                            <Button type="primary">Edit Schedule</Button>
                          </NavLink>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </Card>
          </Card>
        </Col>

        <Col className="payment-edit-container" span={12}>
          <Card className="payment-edit-card">
            {isSuperAdmin() && (
              <>
                <TextArea
                  rows={4}
                  value={notes}
                  onChange={(text) => {
                    setNotes(text.target.value);
                  }}
                  placeholder={"Please enter reason to delete"}
                  style={{ marginBottom: 10 }}
                />

                <Popconfirm
                  title="Are you sure to delete this user?"
                  onConfirm={deleteUser}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="danger">Delete user account</Button>
                </Popconfirm>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    user: state.user,
    timetable: state.timetable,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
