// api status
export const make3 = (name) => [
  name + "_REQUEST",
  name + "_SUCCESS",
  name + "_FAIL",
];
// api status
export const loadmake3 = (name) => [
  name + "_START",
  name + "_COMPLETE",
  name + "_FAIL",
];
// actions for api calling
const promiseTypes = [
  "CHECK_AUTH_TOKEN",
  "GET_USERS",
  "GET_ADMIN_USERS",
  "GET_TEACHER_SCHEDULES",
  "UPDATE_DEACTIVE_TEACHER_SCHEDULE",
  "GET_PAYMENTS",
  "GET_DASHBOARD_STATS",
  "GET_TEACHER_SCHEDULE",
  "GET_TEACHERS",
  "ADD_TEACHER",
  "UPDATE_TEACHER",
  "GET_TEACHER",
  "GET_PAYMENT",
  "UPDATE_TEACHER_SCHEDULE",
  "ADD_CLASS_NOTE",
  "GET_USER",
  "GET_ADMIN_USER",
  "GET_FREE_TRIAL",
  "GET_FREE_TRIALS",
  "APPROVE_PAYMENT",
  "REJECT_PAYMENT",
  "APPROVE_FREE_TRIAL",
  "REJECT_FREE_TRIAL",
  "GET_CLASS_NOTES",
  "GET_CLASS_NOTE",
  "UPDATE_CLASS_NOTE",
  "FAIL_PAYMENT",
  "GET_ANNOUNCEMENTS",
  "GET_GRADES",
  "GET_SUBJECTS",
  "GET_SCHEDULES",
  "ADD_ANNOUNCEMENT",
  "ADD_FILE",
  "ADD_ADMIN_USER",
  "UPDATE_ADMIN_USER",
  "TOGGLE_ACTIVE_ADMIN_USER",
  "DELETE_ANNOUNCEMENT",
  "GET_ANNOUNCEMENT",
  "UPDATE_ANNOUNCEMENT",
  "GET_TIMETABLE",
  "GET_RECEIPTS",
  "DELETE_USER",
  "ADD_TEACHER_SCHEDULE",
  "GET_CLASS_BLOCK_DATES",
  "GET_CLASS_BLOCK_DATE",
  "ADD_CLASS_BLOCK_DATE",
  "DELETE_CLASS_BLOCK_DATE",
  "UPDATE_CLASS_BLOCK_DATE",
].reduce((prev, cur) => {
  make3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for api calling without loader
const loadPromiseTypes = ["LOAD_MORE_VIDEOS"].reduce((prev, cur) => {
  loadmake3(cur).forEach((key) => {
    prev[key] = key;
  });

  return prev;
}, {});

// actions for local data set
const simpleTypes = [
  "SET_ROUTE",
  "SET_ADMININFO",
  "CLEAR_DATA",
  "LOGOUT",
].reduce((prev, cur) => {
  prev[cur] = cur;
  return prev;
}, {});

export const types = { ...simpleTypes, ...promiseTypes, ...loadPromiseTypes };
