import { Button, Col, Input, Row, Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { CSVLink } from "react-csv";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { renderWhatsAppLink } from "../../common/utils";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
  };
  componentDidMount() {
    this.props.getUsers();
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getUsers();
  };

  getColumnSearchProps = (dataIndex, fullMatch = false) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      value = value.toLowerCase();
      var val = record[dataIndex] ? record[dataIndex].toString().toLowerCase() : "";
      if(fullMatch){
        return val === value 
      }
      else{
        return val.includes(value.toLowerCase())
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  columns = [
    {
      title: "Date",
      dataIndex: "date_created",
      key: "date_created",
      ...this.getColumnSearchProps("date_created"),
      render: (_text, record) => (
        <p style={{ margin: 0 }}>
          {moment(record.date_created).format("DD MMM, YYYY hh:mm A")}
        </p>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_text, record) => (
        <Space size="middle">
          <NavLink to={`users/${record.user_id}`}>View</NavLink>
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
      ...this.getColumnSearchProps("full_name"),
      render: (_text, record) => (
        <p style={{ margin: 0 }}>{`${record.full_name}`}</p>
      ),
    },
    {
      title: "Email Id",
      dataIndex: "u_email_id",
      ...this.getColumnSearchProps("u_email_id"),
      key: "u_email_id",
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      key: "contact_number",
      ...this.getColumnSearchProps("contact_number"),
      render: (_text, record) => (
        <p style={{ margin: 0 }}>{renderWhatsAppLink(record.contact_number)}</p>
      ),
    },
    {
      title: "Dad Number",
      dataIndex: "dad_contact",
      key: "dad_contact",
      ...this.getColumnSearchProps("dad_contact"),
      render: (_text, record) => (
        <p style={{ margin: 0 }}>{renderWhatsAppLink(record.dad_contact)}</p>
      ),
    },
    {
      title: "Mom Number",
      dataIndex: "mom_contact",
      key: "mom_contact",
      ...this.getColumnSearchProps("mom_contact"),
      render: (_text, record) => (
        <p style={{ margin: 0 }}>{renderWhatsAppLink(record.mom_contact)}</p>
      ),
    },
    {
      title: "Grade",
      dataIndex: "grade_type",
      ...this.getColumnSearchProps("grade_type"),
      key: "grade_type",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      ...this.getColumnSearchProps("gender", true),
      key: "gender"
    },
    {
      title: "DOB",
      dataIndex: "dob",
      ...this.getColumnSearchProps("dob"),
      key: "dob",
    },
    {
      title: "IC Number",
      dataIndex: "ic_number",
      ...this.getColumnSearchProps("ic_number"),
      key: "ic_number",
    },
    {
      title: "School Type",
      dataIndex: "school_type",
      ...this.getColumnSearchProps("school_type"),
      key: "school_type",
    },
    {
      title: "School Name",
      dataIndex: "school_name",
      ...this.getColumnSearchProps("school_name"),
      key: "school_name",
    },
    {
      title: "City",
      dataIndex: "city",
      ...this.getColumnSearchProps("city"),
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      ...this.getColumnSearchProps("state"),
      key: "state",
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
      ...this.getColumnSearchProps("postcode"),
      key: "postcode",
    },
  ];

  render() {
    let results = [];
    const { usersList } = this.props;
    if (usersList && usersList.length > 0) {
      results = this.props.usersList.map((row, index) => ({
        ...row,
        key: row.user_id,
        sr_no: index + 1,
      }));
    }
    return (
      <BasicLayout className="page-users">
        <Row className="section-title">
          <Col md={24}>
            <h4>Users</h4>
            {usersList && usersList.length > 0 && (
              <CSVLink data={usersList} filename={"startutor-users.csv"}>
                <Button className="btn-download" size="small">
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Col>
        </Row>
        <Row>
          <Table dataSource={results} columns={this.columns} />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    usersList: state.usersInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
