import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { block_date } = props;

  const scheduleId = props.match.params.schedule_id;
  const scheduleCanceledId = props.match.params.block_date_id;

  let defaultValues = {
    class_date: "",
    block_reason: "",
  };
  useEffect(() => {
    props.setCurrentBlockDate(scheduleId, scheduleCanceledId);
  }, [scheduleId, scheduleCanceledId]);

  useEffect(() => {
    const { cancel_reason, class_date } = block_date;
    defaultValues = {
      ...defaultValues,
      block_reason: cancel_reason,
      class_date: moment(class_date),
    };
    form.setFieldsValue(defaultValues);
  }, [form, block_date]);

  const onFinish = (values) => {
    const data = {
      ...values,
    };

    props
      .editClassBlockDate(scheduleCanceledId, { ...data })
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push(
            "/admin/teacher-schedules/" + scheduleId + "/block-dates"
          );
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <div>
                Date:{" "}
                {moment(props.block_date.class_date).format("DD MMM, YYYY")}
              </div>

              <Form.Item
                name="block_reason"
                label="Block reason/note"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Enter Block reason/note"
                />
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Edit Block Date
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    block_date: state.block_date,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
