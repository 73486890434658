import { types as T } from "../actions/action_types";
import { setIn } from "../common/utils";

const initialState = {
  route: null,
  isLoading: 0,
  isLoggedIn: false,
  adminInfo: {},
  usersInfo: [],
  teacher_schedules: [],
  payments: [],
  dashboard: {},
  teacher_schedule: {},
  teachers: [],
  adminusers: [],
  adminuser: {},
  payment: {},
  user: {},
  free_trials: [],
  free_trial: {},
  class_notes: [],
  class_note: {},
  block_dates: [],
  block_date: {},
  announcements: [],
  announcement: {},
  grades: [],
  subjects: [],
  schedules: [],
  timetable: [],
  receipts: [],
};

const requestReg = /_REQUEST/;
const resultReg = /_SUCCESS|_FAIL/;

export default function reducer(state = initialState, action) {
  const isRequest =
    requestReg.test(action.type) &&
    action.type.indexOf("LOAD_USER_INFO") === -1;
  const isResult =
    resultReg.test(action.type) && action.type.indexOf("LOAD_USER_INFO") === -1;
  // set loading state for if any api calling
  if (isRequest) {
    state = { ...state, isLoading: state.isLoading + 1 };
  } else if (isResult) {
    let load = state.isLoading - 1;
    if (load < 0) load = 0;
    state = { ...state, isLoading: load };
  }
  // reducer action and set according in to reducer state
  switch (action.type) {
    case T.SET_ROUTE:
      return {
        ...state,
        route: action.data,
      };

    case T.SET_ADMININFO:
    case T.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        adminInfo: action.data,
      };

    case T.CHECK_AUTH_TOKEN_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          isLoggedIn: true,
          userInfo: action.data.data,
        };
      }
      return { ...state };
    case T.GET_TIMETABLE_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          timetable: action.data.data,
        };
      }
      return { ...state };
    case T.GET_USERS_SUCCESS:
      if (action.data.status) {
        return setIn(["usersInfo"], action.data.data, state);
      }
      return setIn(["usersInfo"], [], state);
    case T.GET_GRADES_SUCCESS:
      if (action.data.status) {
        return setIn(["grades"], action.data.data, state);
      }
      return setIn(["grades"], [], state);
    case T.GET_SUBJECTS_SUCCESS:
      if (action.data.status) {
        return setIn(["subjects"], action.data.data, state);
      }
      return setIn(["subjects"], [], state);
    case T.GET_SCHEDULES_SUCCESS:
      if (action.data.status) {
        return setIn(["schedules"], action.data.data, state);
      }
      return setIn(["schedules"], [], state);

    case T.GET_TEACHER_SCHEDULES_SUCCESS:
      if (action.data.status) {
        return setIn(["teacher_schedules"], action.data.data, state);
      }
      return setIn(["teacher_schedules"], [], state);

    case T.GET_CLASS_NOTES_SUCCESS:
      if (action.data.status) {
        return setIn(["class_notes"], action.data.data, state);
      }
      return setIn(["class_notes"], [], state);

    case T.GET_CLASS_BLOCK_DATES_SUCCESS:
      if (action.data.status) {
        return setIn(["block_dates"], action.data.data, state);
      }
      return setIn(["block_dates"], [], state);

    case T.GET_DASHBOARD_STATS_SUCCESS:
      if (action.data.status) {
        return setIn(["dashboard"], action.data.data, state);
      }
      return setIn(["dashboard"], {}, state);

    case T.GET_PAYMENTS_SUCCESS:
      if (action.data.status) {
        return setIn(["payments"], action.data.data, state);
      }
      return setIn(["payments"], [], state);
    case T.GET_ANNOUNCEMENTS_SUCCESS:
      if (action.data.status) {
        return setIn(["announcements"], action.data.data, state);
      }
      return setIn(["announcements"], [], state);
    case T.GET_TEACHERS_SUCCESS:
      if (action.data.status) {
        return setIn(["teachers"], action.data.data, state);
      }
      return setIn(["teachers"], [], state);
    case T.GET_ADMIN_USERS_SUCCESS:
      if (action.data.status) {
        return setIn(["adminusers"], action.data.data, state);
      }
      return setIn(["adminusers"], [], state);
    case T.GET_ADMIN_USER_SUCCESS:
      if (action.data.status) {
        return setIn(["adminuser"], action.data.data, state);
      }
      return setIn(["adminuser"], [], state);
    case T.GET_TEACHER_SUCCESS:
      if (action.data.status) {
        return setIn(["teacher"], action.data.data, state);
      }
      return setIn(["teacher"], [], state);
    case T.GET_FREE_TRIALS_SUCCESS:
      if (action.data.status) {
        return setIn(["free_trials"], action.data.data, state);
      }
      return setIn(["free_trials"], [], state);

    case T.GET_TEACHER_SCHEDULE_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          teacher_schedule: action.data.data[0],
        };
      }
      return state;

    case T.GET_CLASS_NOTE_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          class_note: action.data.data[0],
        };
      }
      return state;

    case T.GET_CLASS_BLOCK_DATE_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          block_date: action.data.data[0],
        };
      }
      return state;

    case T.GET_PAYMENT_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          payment: action.data.data[0],
        };
      }
    case T.GET_ANNOUNCEMENT_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          announcement: action.data.data[0],
        };
      }
    case T.GET_FREE_TRIAL_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          free_trial: action.data.data[0],
        };
      }
    case T.GET_USER_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          user: action.data.data,
        };
      }
    case T.GET_RECEIPTS_SUCCESS:
      if (action.data.status) {
        return {
          ...state,
          receipts: action.data.data,
        };
      }
    default:
      return state;
  }
}
