import { Alert, Button, Card, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const { Option } = Select;

const EditSchedule = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { kidId, userId } = props.match.params;

  let defaultValues = {
    schedule_id: "",
    schedule_data: [],
  };

  useEffect(() => {
    props.getKidTimeTable(kidId).then((res) => {
      if (res && res.data && res.data.status) {
        var userScheduleData = res.data.data[0];
        props.getSchedules(userScheduleData._grade_id);
        try {
          var schedule_data = JSON.parse(userScheduleData.schedule_data);
          var scheduleIds = [];
          schedule_data.forEach((element) => {
            scheduleIds = scheduleIds.concat(element.schedules);
          });
          defaultValues = {
            schedule_id: userScheduleData.user_schedule_id,
            schedule_data: scheduleIds,
          };
          form.setFieldsValue(defaultValues);
        } catch (err) {}
      }
    });
  }, [kidId]);

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form]);

  const onFinish = (values) => {
    const { schedules } = props;
    const { schedule_data } = values;

    if (schedule_data && schedule_data.length > 0) {
      var scheduleData = [];
      schedules.forEach((element) => {
        if (schedule_data.includes(element.schedule_id)) {
          scheduleData.push({
            scheduleId: element.schedule_id,
            weekDay: element.week_day,
          });
        }
      });

      const data = {
        schedule_id: values.schedule_id,
        schedule_data: scheduleData,
      };

      props
        .updateUserSchedule(kidId, data)
        .then((resp) => {
          if (resp.data && resp.data.status) {
            props.history.push(`/admin/users/${userId}`);
          } else if (resp.err && !resp.err.status) {
            setError(true);
            setErrorMessage(resp.err.message);
          }
        })
        .catch((_err) => {
          setError(true);
          setErrorMessage("Something went wrong!");
        });
    }
  };

  const onAlertClose = () => {
    setError(false);
  };

  const getSubjectName = (schedule) => {
    const {
      subject_name,
      language_code,
      subject_type,
      teacher_name,
      week_day,
      start_time,
      end_time,
    } = schedule;

    var subjectName = subject_name;
    if (language_code !== "") {
      subjectName += ` (${language_code})`;
    }
    if (subject_type !== "") {
      subjectName += ` - (${subject_type})`;
    }

    var name = `${subjectName} - ${teacher_name} - ${week_day} - ${start_time} to ${end_time} `;
    return name;
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={16}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item name="schedule_id">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="schedule_data"
                label="Select teacher's schedule"
                rules={[
                  {
                    required: true,
                    message: "Please select schedule",
                  },
                ]}
              >
                <Select mode="multiple" placeholder="Please select teachers">
                  {props.schedules.map((schedule) => (
                    <Option
                      key={schedule.schedule_id}
                      value={schedule.schedule_id}
                    >
                      {getSubjectName(schedule)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Edit Schedule
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    timetable: state.timetable,
    schedules: state.schedules,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(EditSchedule));
