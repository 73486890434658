import React, { Component } from "react";
import { Table, Space, Tag, Col, Row } from "antd";
import { Button, Input } from "antd";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";

class index extends Component {
  state = {
    searchText: "",
    searchColumn: "",
  };

  componentDidMount() {
    this.props.getFreeTrials();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
    this.props.getFreeTrials(dataIndex, selectedKeys[0]);
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
    this.props.getFreeTrials();
  };

  render() {
    const columns = [
      {
        title: "Student Name",
        dataIndex: "student_name",
        key: "student_name",
        ...this.getColumnSearchProps("student_name"),
      },
      {
        title: "Payment Status",
        dataIndex: "payment_status",
        key: "payment_status",
        ...this.getColumnSearchProps("payment_status"),
        render: (_text, record) => (
          <Space size="middle">
            {record.payment_status === "pending" ? (
              <Tag color="blue">{record.payment_status}</Tag>
            ) : record.payment_status === "success" ? (
              <Tag color="green">{record.payment_status}</Tag>
            ) : (
              <Tag color="red">{record.payment_status}</Tag>
            )}
          </Space>
        ),
      },
      {
        title: "Action By",
        dataIndex: "approved_by",
        key: "approved_by",
        ...this.getColumnSearchProps("approved_by"),
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
      },
      {
        title: "Date Created",
        dataIndex: "date",
        key: "date",
        ...this.getColumnSearchProps("date"),
      },
      {
        title: "Action",
        key: "action",
        render: (_text, record) => (
          <Space size="middle">
            <NavLink to={`/admin/free-trials/${record.trial_transaction_id}`}>
              Edit
            </NavLink>
          </Space>
        ),
      },
    ];
    let results = [];
    if (this.props.free_trials.length > 0) {
      results = this.props.free_trials.map((row, index) => ({
        ...row,
        key: row.trial_transaction_id,
        is_active: row.is_active === 1 ? "Yes" : "No",
      }));
    }

    return (
      <BasicLayout>
        <Row>
          <Col md={20}>
            <h4>FreeTrials</h4>
          </Col>
          <Col md={4}>
            {this.props.free_trials && this.props.free_trials.length > 0 && (
              <CSVLink
                data={this.props.free_trials.map((p) => {
                  return {
                    student_name: p.student_name,
                    payment_status: p.payment_status,
                    approved_by: p.approved_by,
                    notes: p.notes,
                  };
                })}
                filename={"free_trials.csv"}
              >
                <Button type="primary" size="small">
                  Download CSV
                </Button>
              </CSVLink>
            )}
          </Col>
        </Row>
        <Table columns={columns} dataSource={results} />
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    free_trials: state.free_trials,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
