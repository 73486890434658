import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
  Alert,
} from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import "./index.less";

const Index = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { Title } = Typography;
  const { Content } = Layout;

  const onFinish = (values) => {
    setError(false);
    try {
      props
        .loginAdmin({
          email_id: values.email_id,
          password: values.password,
        })
        .then((values) => {
          if (values.data && values.data.status) {
            props.setAdminInfo(values.data.data);
            props.history.push("/admin/dashboard");
          } else {
            var message = values.err ? values.err.message : values.data.message;
            setError(true);
            setErrorMessage(message);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const onAlertClose = () => {
    setError(false);
  };

  return (
    <div className="auth-container">
      <Content>
        <Card className="auth-card">
          <div className="logo">
            <img
              src="/images/logo.png"
              alt="StarTutor logo"
              className="logo-img"
            />
          </div>

          <Title level={2} className="txt-heading">
            Admin Login Panel
          </Title>
          {isError && (
            <Alert
              message="Error"
              description={errorMessage}
              type="error"
              closable
              onClose={onAlertClose}
            />
          )}
          <Row className="form-container">
            <Col span={24}>
              <Form
                form={form}
                size="large"
                layout="vertical"
                onFinish={onFinish}
                className="page-login"
              >
                <Form.Item
                  name="email_id"
                  label="Email ID"
                  className="login-label"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter valid email id",
                    },
                    {
                      required: true,
                      message: "Please enter your email id",
                    },
                  ]}
                >
                  <Input
                    className="login-input"
                    placeholder="Your Email Address"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  className="login-label"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password",
                    },
                    {
                      min: 6,
                      message: "Password must be a minimum of 6 characters",
                    },
                  ]}
                >
                  <Input
                    className="login-input"
                    placeholder="Your Password"
                    type="password"
                  />
                </Form.Item>
                <Button htmlType="submit" type="primary" block>
                  Login
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
      </Content>
    </div>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Index));
