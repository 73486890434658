import { Alert, Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

const Edit = (props) => {
  const [form] = Form.useForm();
  const [isError, setError] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { setCurrentTeacherSchedule, teacher_schedule } = props;

  const scheduleId = props.match.params.schedule_id;

  useEffect(() => {
    setCurrentTeacherSchedule(scheduleId);
  }, [scheduleId, setCurrentTeacherSchedule]);

  useEffect(() => {
    setIsBtnActive(true);
  }, [teacher_schedule]);

  const defaultValues = {
    zoom_class_link: teacher_schedule.zoom_class_link || "",
    is_active: teacher_schedule.is_active === 1 ? true : false,
    is_btn_active: teacher_schedule.is_btn_active === 1 ? true : false,
    button_text: teacher_schedule.button_text || "",
    button_link: teacher_schedule.button_link || "",
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onFinish = (values) => {
    if (!isBtnActive) {
      values.button_text = "";
      values.button_link = "";
    }

    const data = {
      ...values,
      is_active: values.is_active ? 1 : 0,
      is_btn_active: isBtnActive ? 1 : 0,
    };

    props
      .updateTeacherSchedule(scheduleId, data)
      .then((resp) => {
        if (resp.data && resp.data.status) {
          props.history.push("/admin/teacher-schedules");
        } else if (resp.err && !resp.err.status) {
          setError(true);
          setErrorMessage(resp.err.message);
        }
      })
      .catch((_err) => {
        setError(true);
        setErrorMessage("Something went wrong!");
      });
  };

  const onAlertClose = () => {
    setError(false);
  };

  return (
    <BasicLayout>
      <Row>
        <Col className="teacher-schedule-edit-container" span={12}>
          <Card className="teacher-schedule-edit-card">
            {isError && (
              <Alert
                message="Error"
                description={errorMessage}
                type="error"
                closable
                onClose={onAlertClose}
              />
            )}
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
              className="page-edit-teacher-schedule"
              initialValues={defaultValues}
            >
              <Form.Item
                name="zoom_class_link"
                label="Zoom Link"
                className="teacher-schedule-edit-label"
              >
                <Input
                  className="teacher-schedule-edit-input"
                  placeholder="Zoom Link"
                />
              </Form.Item>

              <Button htmlType="submit" type="primary" block>
                Update Schedule
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </BasicLayout>
  );
};

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    teacher_schedule: state.teacher_schedule,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Edit));
