import { Card, Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";

class Dashboard extends Component {
  componentDidMount() {
    this.props.getDashboardStats();
  }

  render() {
    const { dashboard } = this.props;
    return (
      <BasicLayout className="page-dashboard">
        <Row gutter={[15, 20]}>
          <Col xs={24} md={6}>
            <Card
              title="Total Users"
              className="custom-card"
              style={{ backgroundColor: "#2196f3" }}
            >
              <h4>{dashboard?.users || 0}</h4>
            </Card>
          </Col>
          <Col xs={24} md={6}>
            <Card
              title="Current month success payment"
              className="custom-card"
              style={{ backgroundColor: "#00e676" }}
            >
              <h4>{dashboard?.currentMonth || 0}</h4>
            </Card>
          </Col>
        </Row>
        <Row gutter={[15, 20]}>
          <Col xs={24} md={6}>
            <NavLink to={`/admin/payments?status=pending`}>
              <Card
                title="Pending Payment"
                className="custom-card"
                style={{ backgroundColor: "#009688" }}
              >
                <h4>{dashboard?.pending || 0}</h4>
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} md={6}>
            <NavLink to={`/admin/payments?status=payment-success`}>
              <Card
                title="Online Success Payment"
                className="custom-card"
                style={{ backgroundColor: "#2e7d32" }}
              >
                <h4>{dashboard?.successO || 0}</h4>
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} md={6}>
            <NavLink to={`/admin/payments?status=success`}>
              <Card
                title="Manual Success Payment"
                className="custom-card"
                style={{ backgroundColor: "#43a047" }}
              >
                <h4>{dashboard?.successM || 0}</h4>
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} md={6}>
            <NavLink to={`/admin/payments?status=rejected`}>
              <Card
                title="Rejected Payment"
                style={{ backgroundColor: "#ffab00" }}
              >
                <h4>{dashboard?.rejected || 0}</h4>
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} md={6}>
            <NavLink to={`/admin/payments?status=payment-failed`}>
              <Card
                title="Online Failed Payment"
                className="custom-card"
                style={{ backgroundColor: "#dd2c00" }}
              >
                <h4>{dashboard?.failedO || 0}</h4>
              </Card>
            </NavLink>
          </Col>
          <Col xs={24} md={6}>
            <NavLink to={`/admin/payments?status=failed`}>
              <Card
                title="Manual Failed Payment"
                className="custom-card"
                style={{ backgroundColor: "#dd2c00" }}
              >
                <h4>{dashboard?.failedM || 0}</h4>
              </Card>
            </NavLink>
          </Col>
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    dashboard: state.dashboard,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Dashboard));
